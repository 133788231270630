<script setup lang="ts">
const system = useSystemStore();
const { editorIsNavigating } = storeToRefs(system);

const smoothScrolling = ref(false);

onMounted(() => {
  setTimeout(() => {
    smoothScrolling.value = true;
  }, 1000);
});
</script>

<template>
  <div
    :class="`
      flex
      snap-x
      snap-mandatory
      lg:snap-y
      w-full
      lg:flex-col
      max-w-full
      flex-1
      h-full
      pb-0
      overflow-x-auto
      overflow-y-hidden
      lg:overflow-y-auto
      lg:overflow-x-hidden

      ${smoothScrolling ? 'scroll-smooth' : ''}

      ${editorIsNavigating ? '!snap-none !overflow-auto' : ''}
    `"
    id="page-scroller"
  >
    <slot />
  </div>
</template>
