<script setup lang="ts">
import type { FormTheme } from "@/types";
import {
  getThemeById,
  monoThemes,
  backgroundIntensities,
} from "~/assets/colorThemes";

const route = useRoute();
const id = computed(() =>
  Array.isArray(route.params.id) ? undefined : route.params.id
);
const store = useFormStore();
const form = computed(() => store.forms[id.value!]);

const backgroundIntensityOptions = Object.keys(backgroundIntensities);

const themeOptions = monoThemes.map((theme) => ({
  label: theme.label,
  value: theme.value.id,
  __theme: theme.value,
}));
const selectedTheme = ref();

const toggleButtonsClasses = {
  outer: "!mb-2",
  wrapper: "!mb-0",
  options: "grid grid-cols-6 gap-1",
  option: `border border-slate-200 rounded`,
  input: `$reset flex w-full !p-0 rounded overflow-clip items-center justify-center aria-pressed:bg-fk-accent-lightest aria-pressed:text-fk-accent-darker aria-pressed:ring-2 aria-pressed-ring-inset aria-pressed:ring-fk-accent aria-pressed:relative aria-pressed:z-10 transition-all duration-300`,
  help: "text-slate-400 ml-0.5 tracking-tight text-balance",
};

const sliderClasses = {
  fill: "$remove:bg-blue-600 bg-pink-600",
  tooltip:
    "$remove:bg-blue-600 $remove:after:border-t-blue-600 bg-pink-600 after:border-t-pink-600",
};

const checkboxClasses = {
  decorator:
    "$remove:ring-blue-500 $remove:peer-checked:border-blue-600 $remove:peer-checked:text-blue-600 $remove:peer-checked:bg-blue-50 $remove:w-[1em] w-[1.1em] ring-pink-500 peer-checked:border-pink-600 peer-checked:text-pink-600 peer-checked:bg-pink-50",
};

watch(
  () => form?.value?.theme?.colorThemeId,
  (value) => {
    const colorTheme = getThemeById(value, monoThemes);
    if (!colorTheme) return;
    selectedTheme.value = colorTheme;
    document.documentElement.style.setProperty(
      "--fk-color-temp-base",
      colorTheme.temp
    );
    document.documentElement.style.setProperty(
      "--fk-color-accent-base",
      colorTheme.accent
    );
  },
  { immediate: true }
);

watch(
  () => form?.value?.theme?.bgColorIntensity,
  (value: string) => {
    document.documentElement.style.setProperty(
      "--fk-color-temp-page-bg-intensity",
      backgroundIntensities[value]
    );
  },
  { immediate: true }
);
</script>

<template>
  <div class="flex flex-col w-full h-full" v-auto-animate>
    <FormKit
      type="togglebuttons"
      v-model="form.theme.colorThemeId"
      enforced
      :classes="toggleButtonsClasses"
      outer-class="!mb-4"
      :options="themeOptions"
    >
      <template #inputInner="{ option, value }">
        <div
          class="group w-16 rounded-full"
          :data-active="option.value === value"
          v-tooltip="{
            content: `<span class='block text-xs'>${option.label}</span>`,
            html: true,
            distance: 8,
            delay: {
              show: 0,
              hide: 0,
            },
          }"
        >
          <div class="flex items-center justify-center w-full p-1">
            <div
              class="w-3/4 aspect-square rounded-full"
              :style="`background-color: ${option.__theme.accent}`"
            />
          </div>
        </div>
      </template>
    </FormKit>

    <FormKit
      type="togglebuttons"
      label="Background Color Intensity"
      :options="backgroundIntensityOptions"
      :classes="toggleButtonsClasses"
      :outer-class="`!mb-4`"
      options-class="!grid-cols-8"
      help-class="mt-2"
      v-model="form.theme.bgColorIntensity"
      enforced
      help="Shows when background image is disabled or semi-transparent."
    >
      <template #inputInner="{ option }">
        <div
          class="group w-full aspect-square bg-white shadow"
          :style="`background-color: color-mix(
            in srgb,
            var(--fk-color-accent-base) ${
              backgroundIntensities && backgroundIntensities[option.value]
                ? backgroundIntensities[option.value]
                : '2.5%'
            },
            var(--fk-color-temp-form-bg)
          );
          `"
        />
      </template>
    </FormKit>

    <FormKit
      v-if="form.theme.bgImageEnabled"
      type="slider"
      v-model="form.theme.bgImageTransparency"
      label="Background Image Transparency"
      :classes="sliderClasses"
      min="0"
      max="95"
      :delay="0"
      :tooltip-format="(value) => `${value}%`"
      prefix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M3 3v18h18V3'/></svg>"
      suffix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 32 32'><path fill='currentColor' d='M6 6h4v4H6zm4 4h4v4h-4zm4-4h4v4h-4zm8 0h4v4h-4zM6 14h4v4H6zm8 0h4v4h-4zm8 0h4v4h-4zM6 22h4v4H6zm8 0h4v4h-4zm8 0h4v4h-4zm-4-12h4v4h-4zm-8 8h4v4h-4zm8 0h4v4h-4z'/></svg>"
      :marks="[{ at: 0 }, { at: 25 }, { at: 50 }, { at: 75 }]"
    />

    <FormKit
      type="slider"
      v-model="form.theme.formBgTransparency"
      label="Form Container Transparency"
      :classes="sliderClasses"
      min="0"
      max="100"
      :delay="0"
      :tooltip-format="(value) => `${Math.round(value * 0.33)}%`"
      prefix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M3 3v18h18V3'/></svg>"
      suffix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 32 32'><path fill='currentColor' d='M6 6h4v4H6zm4 4h4v4h-4zm4-4h4v4h-4zm8 0h4v4h-4zM6 14h4v4H6zm8 0h4v4h-4zm8 0h4v4h-4zM6 22h4v4H6zm8 0h4v4h-4zm8 0h4v4h-4zm-4-12h4v4h-4zm-8 8h4v4h-4zm8 0h4v4h-4z'/></svg>"
      :marks="[{ at: 0 }, { at: 25 }, { at: 50 }, { at: 75 }, { at: 100 }]"
    />

    <FormKit
      v-if="
        form.theme.formBgTransparency > 0 &&
        ['top-image', 'left-image', 'right-image'].includes(form.theme.layout)
      "
      v-model="form.theme.bgImageCover"
      type="checkbox"
      :classes="checkboxClasses"
      label="Position Image Under Form"
      help="When checked the background image will be positioned under the form."
    />

    <FormKit
      v-if="
        (form.theme.bgImageCover ||
          ['minimal', 'card'].includes(form.theme.layout)) &&
        form.theme.formBgTransparency > 0
      "
      v-model="form.theme.formBgBlur"
      type="checkbox"
      :classes="checkboxClasses"
      label="Blur background"
      help="improves legibility."
    />

    <FormKit
      v-model="form.theme.formBgShadow"
      type="checkbox"
      :classes="checkboxClasses"
      label="Container Shadow"
      help="Toggles the drop shadow for the form container."
    />
  </div>
</template>
