<script setup lang="ts">
const props = defineProps({
  label: String,
});

const emits = defineEmits(["input"]);

const file = ref([]);

const hasFile = computed(
  () => file.value && file.value.length > 0 && file.value[0].file
);

const previewUrl = computed(() => {
  return hasFile.value ? URL.createObjectURL(file.value[0].file) : "";
});

const resetFile = () => {
  file.value = [];
};

watch(previewUrl, () => {
  emits("input", previewUrl);
});
</script>

<template>
  <div
    class="w-full max-w-[300px] rounded-lg flex justify-center items-center overflow-hidden"
    :class="`border-2 border-slate-300 ${!hasFile ? ' border-dashed' : ''}`"
  >
    <div
      v-if="!hasFile"
      class="w-full h-full flex flex-col justify-center items-center text-center"
    >
      <FormKit
        type="file"
        accept="image/jpeg,image/jpg,image/png,image/webp,image/svg+xml"
        v-model="file"
        :classes="{
          outer: 'flex bg-slate-50 !mb-0 p-3',
          inner: '!absolute !inset-0 opacity-0',
          label: 'cursor-pointer flex flex-col items-center',
          noFiles: 'text-gray-500',
        }"
      >
        <template #label>
          <div
            class="flex flex-1 m-auto w-full h-full flex-col justify-center items-center text-center"
          >
            <Icon
              name="f7:cloud-upload"
              size="2em"
              class="text-slate-600 mb-2"
            />
            <span class="text-sm text-slate-600 text-balance">{{ label }}</span>
          </div>
        </template>
      </FormKit>
    </div>
    <div v-else class="group relative w-full h-full">
      <img :src="previewUrl" alt="Preview" class="w-full h-full object-cover" />
      <Button
        size="sm"
        @click="resetFile"
        class="absolute hidden group-hover:!inline-flex top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:!flex"
      >
        <Icon name="mdi:remove" size="1.2em" class="text-slate-200 mr-1" />
        Remove
      </Button>
    </div>
  </div>
</template>
