<script lang="ts" setup>
import formKitConfig from "~/formkit.config";
import { rootClasses } from "~/formkit.app.theme";
import { defaultConfig, FormKitProvider } from "@formkit/vue";
import { cloneAny } from "@formkit/utils";

defineProps<{
  page?: Record<string, any>;
}>();

const route = useRoute();
const id = computed(() =>
  Array.isArray(route.params.id) ? undefined : route.params.id
);
const store = useFormStore();
const { generatingForm } = storeToRefs(store);
const form = computed(() => store.forms[id.value!]);
const theme = computed(() => form.value?.theme);

const baseConfig = cloneAny(formKitConfig());
baseConfig.config!.rootClasses = rootClasses;
baseConfig.icons = {};
const config = defaultConfig(baseConfig);
</script>

<template>
  <div
    v-if="page && page.schema"
    class="form-container relative h-full pointer-events-none [&_.fk-credit]:hidden"
    inert
  >
    <FormKitProvider :config="config">
      <StyleVariableProvider
        v-if="theme"
        :theme="theme"
        class="flex h-full bg-gray-50"
      >
        <div
          v-if="!generatingForm"
          class="page-preview absolute scale-[0.25] flex origin-top-left w-[1024px] aspect-[2/1]"
        >
          <EditorPage
            :theme="theme"
            class="overflow-hidden"
            :static="true"
            :blur-underlay="false"
          >
            <FormKitSchema :schema="page.schema" />
          </EditorPage>
        </div>

        <div v-else class="m-auto flex flex-col items-center gap-2">
          <Loader class="w-6 h-6" />
          <span class="text-xs">Generating form</span>
        </div>
      </StyleVariableProvider>
    </FormKitProvider>
  </div>
</template>

<style scoped>
.page-preview {
}
</style>
