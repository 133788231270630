<script lang="ts" setup>
import formKitConfig from "~/formkit.config";
import { rootClasses } from "~/formkit.app.theme";
import { cloneAny } from "@formkit/utils";
import { defaultConfig, FormKitProvider } from "@formkit/vue";

const baseConfig = cloneAny(formKitConfig());
baseConfig.config!.rootClasses = rootClasses;
baseConfig.icons = {};
const config = defaultConfig(baseConfig);
</script>

<template>
  <FormKitProvider :config="config">
    <slot />
  </FormKitProvider>
</template>
