<script setup lang="ts">
import type { FormStore } from "@/types";

const props = defineProps<{
  index: number;
  page?: Record<string, any>;
  title?: string;
  draggable?: boolean;
  form?: FormStore;
}>();

const { handleMouseOver, handleMouseOut } = useEditorScrollToggle();

const systemStore = useSystemStore();

const { editorActivePageId } = storeToRefs(systemStore);

const pageId = computed(() => {
  return props.page
    ? `form-page-${props.index + 1}`
    : props.title?.toLowerCase().replace(/\s/g, "-");
});
</script>

<template>
  <div
    class="group/page flow-manager-page w-full flex flex-col items-center last:pb-20 select-none"
  >
    <div class="flex flex-col w-full">
      <div class="group flex w-full items-center">
        <div v-if="draggable" class="mr-2 cursor-grab -ml-2 drag-handle">
          <Icon name="icon-park-outline:drag" class="text-gray-600" />
        </div>

        <NuxtLink
          class="w-full"
          :to="`#${pageId}`"
          @mouseover="handleMouseOver"
          @mouseout="handleMouseOut"
        >
          <div
            class="group/page relative w-full flex bg-gray-300 cursor-pointer border border-gray-300 rounded-sm group-hover:border-pink-500 group-hover:ring-1 group-hover:ring-pink-500 has-[.page-edit:hover]:border-gray-300 has-[.page-edit:hover]:ring-0"
            :data-active="editorActivePageId === pageId"
          >
            <div
              class="w-2 -my-px -ml-px group-data-[active=true]/page:bg-pink-500 rounded-l-sm"
            />
            <div class="flex-1">
              <div
                class="flex items-center bg-white rounded-r-sm min-h-10 text-xs font-semibold text-gray-500"
              >
                <div class="flex-1 flex flex-col">
                  <div
                    v-if="draggable"
                    class="w-full relative aspect-[2/1] overflow-clip border-b border-b-gray-200 rounded-tr-sm"
                  >
                    <EditorFlowManagerPagePreview :page="page" />
                  </div>

                  <div class="flex items-center py-1.5 px-2">
                    <span>{{ title }}</span>

                    <div class="ml-auto flex gap-1">
                      <span
                        class="page-edit logic-edit aspect-square w-7 flex items-center justify-center p-1 border border-gray-200 rounded-md hover:text-pink-500 hover:border-pink-500 hover:bg-pink-50 cursor-pointer"
                        v-tooltip="{
                          content: `<span class='block text-xs'>Edit flow logic</span>`,
                          html: true,
                          distance: 8,
                          delay: {
                            show: 0,
                            hide: 0,
                          },
                        }"
                      >
                        <Icon name="ph:tree-view-light" class="w-4 h-4" />
                      </span>
                      <span
                        class="page-edit aspect-square w-7 flex items-center justify-center p-1 border border-gray-200 rounded-md hover:text-pink-500 hover:border-pink-500 hover:bg-pink-50 cursor-pointer"
                        v-tooltip="{
                          content: `<span class='block text-xs'>Page actions</span>`,
                          html: true,
                          distance: 8,
                          delay: {
                            show: 0,
                            hide: 0,
                          },
                        }"
                      >
                        <Icon
                          name="ph:dots-three-outline-vertical-light"
                          class="w-4 h-4"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
