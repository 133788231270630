<script setup lang="ts">
import type { LayoutOptions } from "~/types";

const props = withDefaults(
  defineProps<{
    inputId: string;
    buttonWidth?: string;
    value?: LayoutOptions;
    accentColor?: string;
  }>(),
  {
    buttonWidth: "w-10",
    accentColor: "blue",
    value: "minimal",
  }
);

const chosenValue = ref<LayoutOptions | undefined>(props.value);

const emit = defineEmits<{
  (event: "update:value", value: LayoutOptions): void;
}>();

watch(chosenValue, (value) => {
  emit("update:value", value as LayoutOptions);
});

watch(
  () => props.value,
  (value) => {
    chosenValue.value = value;
  }
);

// Do not remove this comment — it is to make sure Tailwind knows
// about the following classes:
// aria-pressed:ring-blue-300 bg-blue-100 border-blue-300 group-data-[active=true]:border-blue-300 group-data-[active=true]:bg-blue-100
// aria-pressed:ring-pink-300 border-pink-300 group-data-[active=true]:border-pink-300 group-data-[active=true]:bg-pink-100

const toggleButtonsClasses = {
  outer: "!mb-0",
  wrapper: "!mb-0",
  options: "!flex",
  option: "border border-slate-300 mr-1 last:mr-0 rounded-sm",
  input: `$reset flex ${props.buttonWidth} aspect-[5/4] overflow-clip items-center justify-center rounded-sm aria-pressed:bg-white aria-pressed:ring-2 aria-pressed:ring-${props.accentColor}-600 aria-pressed:relative aria-pressed:z-10 transition-all duration-300`,
  help: "text-slate-400 ml-0.5 tracking-tight",
};

const layoutOptions = [
  {
    label: "Minimal, no image",
    shorthand: "Minimal",
    value: "minimal",
  },
  {
    label: "Card centered on top of an image",
    shorthand: "Card over image",
    value: "card",
  },
  {
    label: "Image to the left",
    shorthand: "Left decorative image",
    value: "left-image",
  },
  {
    label: "Image at the top",
    shorthand: "Top decorative image",
    value: "top-image",
  },
  {
    label: "Image to the right",
    shorthand: "Right decorative image",
    value: "right-image",
  },
];

function findOptionByValue(options: any[], value: string | undefined) {
  if (!value) return options[0];
  return options.find((option) => option.value === value);
}
</script>

<template>
  <FormKit
    :id="`${props.inputId}-layout`"
    :key="value"
    help="Layout:"
    name="layout"
    type="togglebuttons"
    enforced
    v-model="chosenValue"
    :classes="toggleButtonsClasses"
    :options="layoutOptions"
  >
    <template #inputInner="{ option, value }">
      <div
        v-tooltip="{
          content: `<span class='block text-xs'>${option.label}</span>`,
          html: true,
          hideTriggers: ['hover'],
        }"
        class="relative w-full h-full"
      >
        <span class="group" :data-active="option.value === value">
          <template v-if="option.value === 'card'">
            <div
              :class="`absolute inset-0 bg-slate-200/30 group-data-[active=true]:bg-${accentColor}-100`"
            >
              <div
                :class="`absolute inset-2 rounded-[2px] bg-white border group-data-[active=true]:bg-white group-data-[active=true]:border-${accentColor}-300 transition-all duration-300`"
              />
            </div>
          </template>
          <template v-if="option.value === 'top-image'">
            <div
              :class="`absolute top-0 w-full h-3 bg-slate-200/30 border-b group-data-[active=true]:bg-${accentColor}-100 group-data-[active=true]:border-${accentColor}-300 transition-all duration-300`"
            />
          </template>
          <template v-if="option.value === 'left-image'">
            <div
              :class="`absolute left-0 w-4 h-full bg-slate-200/30 border-r group-data-[active=true]:bg-${accentColor}-100 group-data-[active=true]:border-${accentColor}-300 transition-all duration-300`"
            />
          </template>
          <template v-if="option.value === 'right-image'">
            <div
              :class="`absolute right-0 w-4 h-full bg-slate-200/30 border-l group-data-[active=true]:bg-${accentColor}-100 group-data-[active=true]:border-${accentColor}-300 transition-all duration-300`"
            />
          </template>
        </span>
      </div>
    </template>

    <template #help="{ help, classes, value }">
      <span :class="classes.help"
        >{{ help }}
        {{ findOptionByValue(layoutOptions, value).shorthand }}</span
      >
    </template>
  </FormKit>
</template>
