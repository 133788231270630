<script setup lang="ts">
import type { FormStore } from "~/types";

const formStore = useFormStore();
const system = useSystemStore();
const { editorPreviewOpen } = storeToRefs(system);
const route = useRoute();
const id = route.params.id as string;
const form = computed<FormStore | undefined>(() => formStore.forms[id]);
const loadIframe = ref(false);
const iframeLoaded = ref(false);

const isSaving = computed(() => {
  return !form.value?.history[form.value?.history?.length - 1]?.created_at;
});

watch(isSaving, () => {
  if (isSaving.value) {
    iframeLoaded.value = false;
    loadIframe.value = false;
  } else {
    loadIframe.value = true;
  }
});

let closeTimer: any;
watch(editorPreviewOpen, () => {
  if (editorPreviewOpen.value) {
    // Clear any existing timer when preview is opened
    if (closeTimer) {
      clearTimeout(closeTimer);
      closeTimer = null;
    }
    loadIframe.value = true;
  } else {
    // Start 90 second timer when preview is closed
    // if the preview has been closed for at least 90 seconds
    // we should reload it when preview is opened again
    closeTimer = setTimeout(() => {
      loadIframe.value = false;
      iframeLoaded.value = false;
      closeTimer = null;
    }, 90 * 1000);
  }
});

// listen for messages from the child iframe container
window.addEventListener("message", (event) => {
  if (event.data.action === "toggleEditor") {
    editorPreviewOpen.value = false;
    // scroll to the page number
    const scrollTarget = document.getElementById(
      `form-page-${event.data.pageIndex}`
    );
    if (scrollTarget) {
      scrollTarget.scrollIntoView({
        behavior: "smooth",
      });
    }
  }
});

const previewUrl = computed(() => {
  return `${route.params.id}/preview`;
});
</script>

<template>
  <div
    class="preview-overlay z-50 overflow-clip rounded-lg bg-white border border-slate-300 shadow-lg"
  >
    <iframe
      v-if="!isSaving && loadIframe"
      :src="previewUrl"
      class="absolute inset-0 w-full h-full"
      frameborder="0"
      loading="lazy"
      @load="iframeLoaded = true"
    />

    <div
      :class="`block absolute inset-0 pointer-events-none bg-white transition-opacity duration-300 ${
        iframeLoaded ? 'opacity-0' : 'opacity-100'
      }`"
    >
      <div class="flex items-center justify-center h-full">
        <Loader class="w-6 h-6 mr-2 text-slate-400" />
        <h3 class="text-slate-500 text-2xl">Loading preview...</h3>
      </div>
    </div>
    <div v-if="isSaving" class="absolute inset-0 bg-white z-10">
      <div class="flex items-center justify-center h-full">
        <Loader class="w-6 h-6 mr-2 text-slate-400" />
        <h3 class="text-slate-500 text-2xl">Saving changes...</h3>
      </div>
    </div>
  </div>
</template>
