<script setup lang="ts">
import { useTimeAgo } from "@vueuse/core";
import type { FormKitSchemaNode, FormTheme } from "~/types";
import { gsap } from "gsap";

const route = useRoute();
const id = route.params.id as string;
const store = useFormStore();
const system = useSystemStore();
const { editorHistoryOpen, isLargeScreen } = storeToRefs(system);
const form = computed(() => store.forms[id]);
const history = computed(() => form.value?.history ?? []);

const isRestoring = ref(false);

const imageQueue = ref<string[]>([]);
const loadedImages = ref<Map<string, string | null>>(new Map());
const concurrentLimit = 10;
const postAnimation = ref(false);
let activeLoads = 0;

const activeHistoryIndex = ref<number>(history.value.length - 1);

const historyTimeLimitCache = new Map<
  string,
  { locked: boolean; removalIn: string }
>();
const historyAccentOffsetCache = new Map<string, string>();

const historyListRef = ref<HTMLUListElement | null>(null);
const historyItemRefs = ref<HTMLLIElement[]>([]);

const bufferSize = 18; // Number of image containers before and after the active index

// Computed property for virtualized image containers
const visibleImages = computed(() => {
  const start = Math.max(0, activeHistoryIndex.value - bufferSize);
  const end = Math.min(
    history.value.length,
    activeHistoryIndex.value + bufferSize + 1
  );
  return history.value.slice(start, end).map((entry, index) => ({
    ...entry,
    actualIndex: start + index, // Keep track of the actual index
  }));
});

const preloadImages = () => {
  if (!history.value) return;

  // Preload images around the active index
  const startIndex = Math.max(0, activeHistoryIndex.value - bufferSize - 5);
  const endIndex = Math.min(
    history.value.length - 1,
    activeHistoryIndex.value + bufferSize + 5
  );

  for (let i = startIndex; i <= endIndex; i++) {
    const id = history.value[i]?.id;
    if (id && !loadedImages.value.has(id) && !imageQueue.value.includes(id)) {
      imageQueue.value.push(id);
    }
  }

  loadNextImages();
};

const loadNextImages = () => {
  while (activeLoads < concurrentLimit && imageQueue.value.length > 0) {
    const id = imageQueue.value.shift();
    if (id) {
      loadImage(id);
    }
  }
};

const retryQueue = ref<string[]>([]);
const retryInterval = 5000;

const loadImage = (id: string, cacheBust = "") => {
  activeLoads++;
  const img = new Image();
  const url = `https://screens.formkit.ai/${id}.webp${
    cacheBust ? `?cacheBust=${cacheBust}` : ""
  }`;
  img.onload = () => {
    loadedImages.value.set(id, url);
    activeLoads--;
    loadNextImages();
    // Remove from retry queue if it was there
    const index = retryQueue.value.indexOf(id);
    if (index > -1) {
      retryQueue.value.splice(index, 1);
    }
  };
  img.onerror = () => {
    activeLoads--;
    if (!retryQueue.value.includes(id)) {
      retryQueue.value.push(id);
    }
    loadNextImages();
  };
  img.src = url;
};

const retryFailedImages = () => {
  retryQueue.value.forEach((id) => {
    if (!loadedImages.value.has(id)) {
      loadImage(id, Date.now().toString());
    } else {
      retryQueue.value.splice(retryQueue.value.indexOf(id), 1);
    }
  });
};
const retryIntervalId = setInterval(retryFailedImages, retryInterval);

const setActiveHistory = (index: number, immediate = false) => {
  scrollToCenter(index, immediate);
};

const scrollToCenter = (index: number, immediate = false) => {
  if (historyListRef.value && historyItemRefs.value[index]) {
    const listRect = historyListRef.value.getBoundingClientRect();
    const itemRect = historyItemRefs.value[index].getBoundingClientRect();

    if (isLargeScreen.value) {
      const scrollTop = historyListRef.value.scrollTop;
      const centerOffset = (listRect.height - itemRect.height) / 2;
      const targetScrollTop =
        scrollTop + itemRect.top - listRect.top - centerOffset;
      historyListRef.value.scrollTo({
        top: targetScrollTop,
        behavior: immediate ? "instant" : "smooth",
      });
    } else {
      const scrollLeft = historyListRef.value.scrollLeft;
      const centerOffset = (listRect.width - itemRect.width) / 2;
      const targetScrollLeft =
        scrollLeft + itemRect.left - listRect.left - centerOffset;
      historyListRef.value.scrollTo({
        left: targetScrollLeft,
        behavior: immediate ? "instant" : "smooth",
      });
    }
  }
};

function getOpacity(index: number, currentIndex: number) {
  if (index === currentIndex) return 1;
  if (index > currentIndex) return 0;
  return Math.max(0, 1 - (currentIndex - index) * 0.04);
}

function getTransform(index: number, currentIndex: number) {
  const distance = currentIndex - index;

  if (distance === 0) {
    return "translateY(0) scale(1)";
  }

  if (distance > 0) {
    const scaleFactor = 1 / (1 + distance * 0.11);
    const translateY = `${distance * -18 * scaleFactor}px`;
    const scale = 1 - distance * 0.133 * scaleFactor;
    return `translateY(${translateY}) scale(${scale})`;
  } else {
    return `translateY(0) scale(1)`;
  }
}

function getZIndex(index: number, currentIndex: number, historyLength: number) {
  return historyLength - Math.abs(index - currentIndex);
}

const updateActiveHistoryOnScroll = () => {
  if (!historyListRef.value) return;

  const listRect = historyListRef.value.getBoundingClientRect();
  const isVerticalScroll = isLargeScreen.value;
  const listCenter = isVerticalScroll
    ? listRect.top + listRect.height / 2
    : listRect.left + listRect.width / 2;
  let closestIndex = -1;
  let closestDistance = Infinity;

  historyItemRefs.value.forEach((el, index) => {
    const rect = el.getBoundingClientRect();
    const distance = isVerticalScroll
      ? Math.abs(rect.top + rect.height / 2 - listCenter)
      : Math.abs(rect.left + rect.width / 2 - listCenter);

    if (distance < closestDistance) {
      closestDistance = distance;
      closestIndex = index;
    }

    const maxDistance = isVerticalScroll
      ? listRect.height / 8
      : listRect.width / 3;
    const scaleRange = 0.1;
    const scale = Math.max(0, 1 - (distance / maxDistance) * scaleRange);
    const finalScale = Math.max(0.9, scale);

    el.style.transform = `scale(${finalScale})`;
    el.style.transformOrigin = isVerticalScroll
      ? "right center"
      : "center bottom";
  });

  if (closestIndex !== -1 && closestIndex !== activeHistoryIndex.value) {
    activeHistoryIndex.value = closestIndex;
    preloadImages(); // Preload images around the new active index
  }
};

const handleMainImageAreaScroll = (event: WheelEvent) => {
  event.preventDefault();
  if (historyListRef.value) {
    if (isLargeScreen.value) {
      historyListRef.value.scrollTop += event.deltaY;
    } else {
      historyListRef.value.scrollLeft += event.deltaY;
    }
  }
};

const handleHistoryItemClick = (index: number) => {
  setActiveHistory(index);
};

const formatDate = (date: string, format: "long" | "short" = "long") => {
  const dateObj = new Date(date);
  return format === "long"
    ? dateObj.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : dateObj
        .toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, ".");
};

const isSameDay = (date1: string, date2: string) => {
  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);
  return date1Obj.toDateString() === date2Obj.toDateString();
};

const updateLayout = () => {
  if (historyListRef.value && historyItemRefs.value.length > 0) {
    if (isLargeScreen.value) {
      const listHeight = window.innerHeight - 50;
      historyListRef.value.style.paddingTop = `${listHeight / 2}px`;
      historyListRef.value.style.paddingBottom = `${listHeight / 2}px`;
      historyListRef.value.style.paddingLeft = `0px`;
      historyListRef.value.style.paddingRight = `0px`;
    } else {
      const listWidth = window.innerWidth - 50;
      historyListRef.value.style.paddingLeft = `${listWidth / 2}px`;
      historyListRef.value.style.paddingRight = `${listWidth / 2}px`;
      historyListRef.value.style.paddingTop = `0px`;
      historyListRef.value.style.paddingBottom = `0px`;
    }
  }
};

const restoreVersion = async () => {
  // TODO: make this indicate that it was a restored history state
  // and do instant replacement rather than relying on auto-save timeout
  const historyId = history.value[activeHistoryIndex.value].id;
  isRestoring.value = true;

  const { data, error } = await useFetch(
    `/api/forms/${id}/history/${historyId}`
  );

  if (error.value) {
    console.error(error.value);
    return;
  }

  if (data.value?.schema && data.value?.theme) {
    form.value.schema = data.value?.schema as FormKitSchemaNode[];
    form.value.theme = data.value?.theme as FormTheme;
  }

  isRestoring.value = false;
  editorHistoryOpen.value = false;
};

const populateTimeLimitObject = (date: string) => {
  const dateObj = new Date(date);
  const now = Date.now();
  const twoDaysInMillis = 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds
  const daysUntilRemoval = Math.max(
    0,
    30 - Math.floor((now - dateObj.getTime()) / (1000 * 60 * 60 * 24))
  );
  return {
    locked: dateObj.getTime() < now - twoDaysInMillis,
    removalIn:
      daysUntilRemoval > 0
        ? `Removal in <span class="${
            daysUntilRemoval <= 10 ? "text-red-500" : "text-orange-500"
          }">${daysUntilRemoval} day${daysUntilRemoval !== 1 ? "s" : ""}</span>`
        : "<span class='text-red-500'>Scheduled for deletion</span>",
  };
};

const handleKeyDown = (event: KeyboardEvent) => {
  const step = event.shiftKey ? 10 : 1;
  if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
    setActiveHistory(Math.max(0, activeHistoryIndex.value - step));
  } else if (event.key === "ArrowDown" || event.key === "ArrowRight") {
    setActiveHistory(
      Math.min(history.value.length - 1, activeHistoryIndex.value + step)
    );
  }
  if (event.key === "Enter") {
    restoreVersion();
  }
};

const setupKeyboardShortcuts = () => {
  window.addEventListener("keydown", handleKeyDown);
};

const tearDownKeyboardShortcuts = () => {
  window.removeEventListener("keydown", handleKeyDown);
};

watch(isLargeScreen, (newVal) => {
  if (newVal) {
    updateLayout();
    scrollToCenter(activeHistoryIndex, true);
  }
});

watchEffect(() => {
  const latestEntry = history.value[history.value.length - 1];
  if (latestEntry.id && !loadedImages.value.has(latestEntry.id)) {
    loadImage(latestEntry.id);
  }
});

onMounted(() => {
  preloadImages();
  setupKeyboardShortcuts();
  if (history.value && history.value.length > 0) {
    nextTick(() => {
      updateLayout();
      if (historyListRef.value) {
        if (isLargeScreen.value) {
          historyListRef.value.scrollTop = historyListRef.value.scrollHeight;
        } else {
          historyListRef.value.scrollLeft = historyListRef.value.scrollWidth;
        }
      }
      setActiveHistory(history.value.length - 1, true);
    });
    // pre-cache the history time limits and offsets
    history.value.forEach((entry, index) => {
      if (entry.created_at) {
        historyTimeLimitCache.set(
          entry.id,
          populateTimeLimitObject(entry.created_at)
        );
        historyAccentOffsetCache.set(
          entry.id,
          `${(index / (history.value?.length - 1)) * 95}%`
        );
      }
    });
  }

  setTimeout(() => {
    postAnimation.value = true;
    requestAnimationFrame(() => {
      gsap.fromTo(
        "#history-nebula",
        { opacity: 0 },
        { opacity: 1, duration: 0.25, ease: "power2.out" }
      );
    });
  }, 350);

  window.addEventListener("resize", updateLayout);
});

watch(editorHistoryOpen, () => {
  if (!editorHistoryOpen.value) {
    gsap.to("#history-nebula", {
      opacity: 0,
      duration: 0.25,
      ease: "power2.out",
      onComplete: () => {
        postAnimation.value = false;
      },
    });
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", updateLayout);
  tearDownKeyboardShortcuts();
  clearInterval(retryIntervalId);
});
</script>

<template>
  <div class="absolute inset-0 bg-slate-950 z-50 select-none">
    <div
      :class="`flex h-[calc(100dvh-50px)] ${
        isLargeScreen ? 'flex-row' : 'flex-col'
      }`"
    >
      <!-- Main Image Area -->
      <EditorHistoryNebula
        v-if="postAnimation"
        id="history-nebula"
        :active-index="activeHistoryIndex"
        :origin-x="40"
        :origin-y="2"
      />
      <div
        :class="`
        p-4 
        overflow-hidden 
        relative 
        ${isLargeScreen ? 'w-3/4' : 'h-[calc(75dvh)]'}
      `"
      >
        <div
          class="h-full flex flex-col items-center justify-center"
          v-if="postAnimation"
          @wheel="handleMainImageAreaScroll"
        >
          <div
            ref="historyContainer"
            class="@container relative w-full h-full perspective-1000 z-10"
          >
            <template v-for="(entry, index) in visibleImages" :key="entry.id">
              <div
                class="group absolute top-1/2 -translate-y-1/2 lg:-translate-y-[55%] left-0 w-full data-[is-top=true]:!z-10 data-[is-top=false]:pointer-events-none"
                :data-is-top="activeHistoryIndex - entry.actualIndex === 0"
                :data-is-locked="historyTimeLimitCache.get(entry.id!)?.locked"
              >
                <div
                  v-if="entry.id"
                  @wheel.stop
                  :class="`
                    bg-slate-800
                    border
                    border-slate-700
                    p-5
                    h-[50vh]
                    lg:h-[60vh]
                    rounded-lg
                    overflow-y-auto
                    origin-top
                    max-w-[1200px]
                    mx-auto
                  `"
                  :data-index="index"
                  :data-actual-index="entry.actualIndex"
                  :style="{
                    opacity: getOpacity(entry.actualIndex, activeHistoryIndex),
                    transform: getTransform(
                      entry.actualIndex,
                      activeHistoryIndex
                    ),
                    zIndex: getZIndex(
                      entry.actualIndex,
                      activeHistoryIndex,
                      history?.length ?? 0
                    ),
                  }"
                >
                  <span
                    :key="`progress-${entry.id}`"
                    class="absolute top-0 w-[5%] h-[1px] bg-gradient-to-r from-transparent via-white/40 to-transparent rounded-full z-20"
                    :style="{
                      left: historyAccentOffsetCache.get(entry.id) || '95%',
                    }"
                  />
                  <span
                    class="fixed bg-slate-800 rounded-lg px-2 py-0.5 top-0 -left-0.5 text-[10px] text-slate-400/80 z-10 tabular-nums"
                  >
                    V.{{ entry.actualIndex + 1 }} — {{ entry.page_count }}
                    {{ entry.page_count === 1 ? "page" : "pages" }}
                    <Icon
                      name="material-symbols:lock-outline"
                      class="hidden group-data-[is-locked=true]:inline-block w-3 h-3 ml-1 -mb-0.5"
                    />
                  </span>
                  <span
                    class="fixed bg-slate-800 rounded-lg px-2 py-0.5 top-0 right-0.5 text-[10px] text-slate-400/80 z-10 tabular-nums"
                  >
                    {{
                      new Date(entry.created_at + "Z").toLocaleString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })
                    }}
                  </span>

                  <div
                    class="grid grid-cols-2 gap-2 @lg:gap-4 @5xl:grid-cols-3"
                  >
                    <template v-if="loadedImages.get(entry.id)">
                      <div
                        v-for="pageIndex in entry.page_count"
                        :key="`${entry.id}-${pageIndex}`"
                        class="relative w-full aspect-[3/2] overflow-hidden bg-slate-700 rounded-sm hover:ring-2 hover:ring-pink-500 hover:ring-offset-2 hover:ring-offset-slate-700"
                      >
                        <NuxtLink
                          :to="`/editor/${id}/history/${history[activeHistoryIndex].id}/${pageIndex}`"
                          target="_blank"
                          class="block"
                        >
                          <img
                            :src="loadedImages.get(entry.id)"
                            :style="{
                              position: 'absolute',
                              top: `${-100 * (pageIndex - 1)}%`,
                              left: 0,
                              width: '100%',
                              height: 'auto',
                            }"
                            :alt="`History entry ${
                              entry.actualIndex + 1
                            }, page ${pageIndex}`"
                            class="object-cover"
                          />
                        </NuxtLink>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        v-for="pageIndex in entry.page_count"
                        :key="`${entry.id}-placeholder-${pageIndex}`"
                        class="bg-slate-700 rounded-lg w-full aspect-[3/2] flex items-center justify-center"
                      >
                        <p class="text-white text-sm leading-none">
                          <Loader class="-mb-0.5 mr-1" /> Generating preview...
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>

            <div
              v-if="historyTimeLimitCache.get(history[activeHistoryIndex].id!)?.locked"
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white/80 backdrop-blur-md rounded-lg p-5 w-full max-w-[500px] border-2 border-pink-500 text-xl text-slate-800 z-10"
            >
              <span class="flex flex-wrap items-center">
                <span class="block w-full">
                  <Icon
                    name="material-symbols:lock-outline"
                    class="w-5 h-5 mr-0.5 -ml-0.5 -mb-0.5 inline-block"
                  />
                  Locked
                </span>
                <span class="flex items-center mt-1 mb-4 text-xs">
                  <Icon name="mdi:clock-outline" class="w-4 h-4 mr-1 mb-0.5" />
                  <span
                    v-html="historyTimeLimitCache.get(history[activeHistoryIndex].id!)?.removalIn"
                  />
                </span>
              </span>

              <p class="text-xs lg:text-sm text-slate-800">
                <span v-if="isLargeScreen"
                  >Free accounts are limited to 48 hours of revision history
                  access. <br /><br
                /></span>
                Please
                <NuxtLink
                  to="/pricing"
                  class="text-pink-500 hover:text-pink-600"
                  >upgrade to a paid plan</NuxtLink
                >
                to access older revisions.
                <span v-if="isLargeScreen"
                  >History is stored for 30 days for free accounts before being
                  removed. Paid accounts can store and access up to 365 days of
                  history.</span
                >
              </p>
            </div>
          </div>

          <div
            :class="`
              flex items-center justify-center gap-2 absolute left-1/2 -translate-x-1/2 mb-auto z-10 select-none
              ${isLargeScreen ? 'bottom-6' : 'bottom-0'}
            `"
          >
            <div class="flex flex-col gap-2">
              <Button
                :disabled="activeHistoryIndex === 0"
                size="sm"
                class="!bg-slate-800 border border-slate-700 !text-white hover:!bg-slate-700 disabled:opacity-50 disabled:cursor-not-allowed"
                @click="
                  setActiveHistory(
                    Math.max(0, activeHistoryIndex - ($event.shiftKey ? 10 : 1))
                  )
                "
              >
                <Icon name="mdi:chevron-up" class="w-5 h-5" />
                Older
              </Button>
              <Button
                :disabled="activeHistoryIndex === history.length - 1"
                size="sm"
                class="!bg-slate-800 border border-slate-700 !text-white hover:!bg-slate-700 disabled:opacity-50 disabled:cursor-not-allowed"
                @click="
                  setActiveHistory(
                    Math.min(
                      history.length - 1,
                      activeHistoryIndex + ($event.shiftKey ? 10 : 1)
                    )
                  )
                "
              >
                <Icon name="mdi:chevron-down" class="w-5 h-5" />
                Newer
              </Button>
            </div>
            <div
              :class="`
                bg-slate-800 border border-slate-700 px-5 py-5 flex items-center justify-center gap-2 rounded-xl text-white
                ${isLargeScreen ? 'flex-row' : 'flex-col'}
              `"
            >
              <NuxtLink
                :to="`/editor/${id}/history/${history[activeHistoryIndex].id}/1`"
                target="_blank"
                :class="`block ${isLargeScreen ? 'w-auto' : 'w-full'}`"
              >
                <Button
                  size="sm"
                  :class="`
                    !bg-slate-700 border border-slate-600 !text-white hover:!bg-slate-600
                    ${isLargeScreen ? 'w-auto' : 'w-full'}
                  `"
                >
                  <Icon name="material-symbols:preview" class="w-4 h-4 mr-1" />
                  Preview
                </Button>
              </NuxtLink>
              <Button
                :disabled="
                  activeHistoryIndex === history.length - 1 || isRestoring || historyTimeLimitCache.get(history[activeHistoryIndex].id!)?.locked
                "
                size="sm"
                :class="`!bg-pink-500 hover:!bg-pink-600 !text-white disabled:!bg-slate-700 disabled:opacity-50 disabled:cursor-not-allowed`"
                @click="restoreVersion"
              >
                <Loader v-if="isRestoring" class="w-4 h-4 inline-block mr-1" />
                <Icon
                  v-if="historyTimeLimitCache.get(history[activeHistoryIndex].id!)?.locked"
                  name="material-symbols:lock-outline"
                  class="w-4 h-4 mr-1 inline-block"
                />
                {{
                  activeHistoryIndex === history.length - 1
                    ? "Latest Version"
                    : isRestoring
                    ? "Restoring..."
                    : "Restore Version"
                }}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <!-- History Entries List -->
      <div
        :class="`overflow-hidden relative z-10 ${
          isLargeScreen ? 'w-1/4' : 'w-full h-[calc(25dvh-50px)] pt-4'
        }`"
      >
        <ul
          ref="historyListRef"
          :class="`
            snap-always
            snap-center

            ${
              isLargeScreen
                ? 'h-full overflow-y-auto snap-y pr-2 pl-8'
                : 'w-full overflow-x-auto snap-x pb-2 pt-10 flex'
            }
          `"
          @scroll="updateActiveHistoryOnScroll"
          @keydown.up.prevent
          @keydown.down.prevent
        >
          <template v-for="(entry, index) in history" :key="entry.id ?? index">
            <li
              v-if="
                entry.id && (
                  index === 0 ||  
                  !isSameDay(entry.created_at!, history[index - 1].created_at!)
                )
              "
              :class="`
                text-sm 
                text-slate-900 
                font-semibold 
                p-2 
                bg-slate-300 
                z-10 
                rounded

                ${
                  isLargeScreen
                    ? 'my-4 ml-3'
                    : 'mx-2 mt-0 w-[40px] flex justify-center items-center'
                }
              `"
            >
              <template v-if="isLargeScreen">
                {{ formatDate(entry.created_at!) }}
              </template>
              <template v-else>
                <div
                  class="tracking-wide -rotate-90 -translate-y-full whitespace-nowrap"
                >
                  {{ formatDate(entry.created_at!, "short") }}
                </div>
              </template>
            </li>
            <li
              :ref="el => { if (el) historyItemRefs[index] = el as HTMLLIElement }"
              :data-active="index === activeHistoryIndex"
              :data-published="!!(entry.published_at || entry.published)"
              :data-is-locked="historyTimeLimitCache.get(entry.id!)?.locked"
              :class="`
                group
                relative
                p-2
                rounded
                flex
                justify-between
                cursor-pointer
                mb-2
                ring-2
                bg-slate-900
                ring-slate-700

                data-[active=true]:bg-slate-700
                data-[published=true]:bg-green-800
                data-[published=true]:ring-green-500
                data-[published=false]:ring-slate-700

                ${
                  isLargeScreen
                    ? 'mb-2 items-center'
                    : 'min-w-[180px] mx-0 items-start'
                }
              `"
              @click="handleHistoryItemClick(index)"
            >
              <div v-if="entry.id">
                <p
                  class="text-xs text-slate-400 mb-0.5 group-data-[published=true]:text-green-400"
                >
                  <Icon
                    name="material-symbols:lock-outline"
                    class="hidden group-data-[is-locked=true]:inline-block absolute top-1 right-1 w-4 h-4 mr-1"
                  />
                  {{ useTimeAgo(new Date(entry.created_at + "Z")).value }}
                  <span v-if="entry.published" class="text-green-400 text-xs"
                    >— Currently Published</span
                  >
                  <span
                    v-else-if="entry.published_at"
                    class="text-green-400 text-xs"
                    >— Previously Published</span
                  >
                </p>
                <p class="text-white text-sm">
                  {{ entry.description || `Entry ${index + 1}` }}
                </p>
              </div>
              <div v-else class="text-white text-sm">
                <Loader class="w-4 h-4 inline-block" /> Saving...
              </div>
            </li>
          </template>
          <li
            :class="`text-xs text-slate-400/60 text-center w-full py-1 ${
              isLargeScreen ? '' : 'mb-auto mt-16 ml-4 -mr-20'
            }`"
          >
            {{ isLargeScreen ? "Scroll" : "Swipe" }}
            <span v-if="isLargeScreen">or use arrow keys</span> to navigate.
            <br />
            <span v-if="isLargeScreen" class="text-xs text-slate-400/60">
              (Shift+Arrow to step by 10)
            </span>
            <span v-else class="text-xs text-slate-400/60"> <——> </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
