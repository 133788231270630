<script setup lang="ts">
const props = defineProps<{
  system?: Boolean;
  required?: Boolean;
}>();

const pageContainerClassList = `
  snap-always
  snap-center
  scroll-my-12
  flex
  shrink-0
  max-h-[calc(100vh-160px)]
  ${props.system ? "h-1/2" : "h-full"}

  w-[calc(100%-32px)]
  mx-2
  last:pr-4
  lg:last:pr-0
  lg:mx-0
  lg:my-2
  lg:w-auto
  lg:first:pt-0
  lg:last:pb-20
`;

const pageClassList = `
  border-2
  border-white
  rounded-lg
  overflow-clip
  flex
  flex-1
  flex-col
  shrink-0
  bg-white
  w-full
  xl:mx-3
  ${
    props.system
      ? "shadow-[0px_0px_0px_1px_#64748b,0px_0px_0px_3px_rgba(255,255,255,1),0px_8px_12px_-5px_rgba(0,0,0,0.15)]"
      : "shadow-[0px_0px_0px_1px_#d1d5db,0px_0px_0px_3px_rgba(255,255,255,1),0px_8px_12px_-5px_rgba(0,0,0,0.15)]"
  }
`;
</script>

<template>
  <div :class="pageContainerClassList">
    <div :class="pageClassList">
      <template v-if="system">
        <div
          class="sticky -top-[24px] h-[40px] z-20 flex items-center text-sm py-1.5 px-2 shrink-0 bg-slate-500 text-slate-50 font-semibold border border-transparent"
        >
          <slot name="name" />
        </div>
      </template>
      <template v-else>
        <div
          class="sticky -top-[24px] h-[40px] z-20 w-full bg-gray-100 border-b border-b-gray-200 flex shrink-0 items-center px-1"
        >
          <div
            class="flex items-center text-sm py-0.5 px-2 text-slate-500 font-semibold border border-transparent hover:bg-pink-50 hover:border-pink-500 hover:text-pink-500 rounded-md"
          >
            <slot name="name" />
          </div>

          <div class="ml-auto flex gap-1 items-center">
            <span
              class="aspect-square w-7 flex items-center justify-center p-1 border border-gray-300 rounded-md hover:text-pink-500 hover:border-pink-500 hover:bg-pink-50 cursor-pointer"
              v-tooltip="{
                content: `<span class='block text-xs'>Page actions</span>`,
                html: true,
                distance: 8,
                delay: {
                  show: 0,
                  hide: 0,
                },
              }"
            >
              <Icon
                name="ph:dots-three-outline-vertical-light"
                class="w-4 h-4"
              />
            </span>
          </div>
        </div>
      </template>
      <div
        :class="`
          relative
          z-10
          flex
          h-full
          mt-0.5
          max-h-[calc(100%-32px)]
          [content-visibility:auto]
          [contain-intrinsic-size:100px_80vh]
          [contain:content]

          ${props.system ? 'bg-white ' : 'bg-white '}
        `"
      >
        <div class="overflow-auto w-full max-h-full flex">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
