<script setup lang="ts">
import type { FormKitNode } from "@formkit/core";
import type { FormStore } from "@/types";

const props = defineProps<{
  form: FormStore;
}>();

const cleanLoad = ref(false);
const imageLoaded = ref(false);
const inputId = "image-search-input";
const inputElement = ref<HTMLInputElement | null>(null);
const currentPage = ref(1);
const isLoading = ref(false);

const images = computed(() => {
  return props.form?.bgImageSearchResults?.results || [];
});
const totalPages = computed(() => {
  return props.form?.bgImageSearchResults?.total_pages || 0;
});

const handleImageLoaded = () => {
  imageLoaded.value = true;
};

const focusInput = () => {
  if (!inputElement.value) return;
  inputElement.value.focus();
  inputElement.value.select();
};

const searchInput = (node: FormKitNode) => {
  // autofocus input when it mounts
  setTimeout(() => {
    if (node && node.context) {
      inputElement.value = document.getElementById(inputId) as HTMLInputElement;
      if (inputElement.value) {
        focusInput();
      }
    }
  }, 50);
};

const useImage = (image: any) => {
  if (!props.form || !props.form.theme) return;
  props.form.theme.bgImageUrl = `${image.urls.full}&w=1600&h=1600&&fm=webp`;
  props.form.theme.bgImageThumbUrl = `${image.urls.thumb}&w=200&h=200&&fm=webp`;
  props.form.theme.bgImageBlurHash = image.blur_hash;
  imageLoaded.value = false;
};

function handleSearch() {
  cleanLoad.value = true;
  currentPage.value = 0;
  loadNextPage();
}

async function loadNextPage() {
  if (isLoading.value) return;
  try {
    isLoading.value = true;
    currentPage.value += 1;
    const searchResults = await $fetch("/unsplash/search", {
      method: "get",
      responseType: "json",
      query: {
        query: props.form.theme.bgImageSearchQuery,
        page: currentPage.value,
      },
    });

    if (searchResults) {
      if (cleanLoad.value) {
        props.form.bgImageSearchResults = searchResults;
      } else {
        props.form.bgImageSearchResults?.results.push(...searchResults.results);
      }
    }

    isLoading.value = false;
    cleanLoad.value = false;
  } catch (error) {
    isLoading.value = false;
  }
}

watch(
  () => props.form.theme,
  () => {
    if (
      !props.form.bgImageSearchResults &&
      props.form.theme?.bgImageSearchQuery
    ) {
      handleSearch();
    }
  }
);
</script>

<template>
  <VDropdown :placement="'right-start'" :distance="8" :delay="0">
    <template #popper>
      <div
        class="min-w-[400px] w-full max-w-[600px] max-h-[min(1000px,92dvh)] overflow-auto"
      >
        <div class="p-4 sticky top-0 bg-white z-50 border-b border-slate-200">
          <FormKit
            :plugins="[searchInput]"
            :id="inputId"
            type="text"
            :disabled="isLoading"
            v-model="form.theme.bgImageSearchQuery"
            suffix-icon="search"
            outer-class="!mb-0"
            @suffix-icon-click="focusInput"
            @keypress.enter="handleSearch()"
          >
            <template #prefix>
              <Loader v-if="isLoading" class="mr-2" />
            </template>
          </FormKit>
        </div>

        <div class="grid grid-cols-2 gap-2 px-4 py-4">
          <div
            v-for="image in images"
            :key="image.id"
            class="relative aspect-[16/9]"
            v-close-popper
          >
            <div class="absolute inset-0 rounded overflow-clip">
              <img
                :src="image.urls.thumb"
                class="absolute z-20 top-0 left-0 w-full h-full object-cover object-center"
                loading="lazy"
              />
              <BlurHash
                :hash="image.blur_hash"
                class="absolute top-0 left-0 w-full h-full object-cover object-center"
              />
            </div>
            <div
              class="absolute z-20 opacity-0 hover:opacity-100 inset-0 bg-white bg-opacity-0 hover:bg-opacity-50 transition-opacity cursor-pointer"
              @click="useImage(image)"
            >
              <div
                class="absolute inset-0 flex items-center justify-center mb-4"
              >
                <Button size="sm" class="pointer-events-none">
                  <span class="text-xs">Use Image</span>
                </Button>
              </div>

              <div
                class="absolute bottom-0 left-0 right-0 py-1 px-2 bg-white bg-opacity-50 z-10"
              >
                <p class="text-xs text-slate-600 truncate text-center">
                  Photo by
                  <a
                    class="text-pink-800 hover:underline"
                    target="_blank"
                    :href="`https://unsplash.com/@${image?.user?.username}?utm_source=formkit&utm_medium=referral`"
                    >{{ image?.user?.name }}</a
                  >
                </p>
              </div>
            </div>
          </div>

          <div class="col-span-2" v-if="currentPage < totalPages">
            <Button
              size="sm"
              variant="outline"
              class="w-full"
              @click="loadNextPage"
            >
              <Loader v-if="isLoading" class="mr-2" />
              {{ isLoading ? "Loading..." : "Load More" }}
            </Button>
          </div>
        </div>
      </div>
    </template>

    <div class="group relative w-full aspect-[16/7] mb-4 cursor-pointer">
      <div class="absolute inset-0 rounded-lg overflow-clip">
        <div
          :class="`!absolute z-[1] top-0 left-0 w-full h-full fk-filter-${form?.theme?.bgFilter}`"
          :style="`opacity: ${form?.theme?.bgFilterIntensity / 100};`"
        >
          <img
            :src="form.theme.bgImageUrl"
            class="absolute top-0 left-0 w-full h-full object-cover object-center"
            loading="lazy"
            :onload="handleImageLoaded"
          />
        </div>
        <img
          :src="form.theme.bgImageUrl"
          loading="lazy"
          class="absolute top-0 left-0 w-full h-full object-cover object-center"
        />
        <BlurHash
          v-if="!imageLoaded"
          class="!absolute inset-0"
          :hash="form.theme && form.theme.bgImageBlurHash"
        />
      </div>

      <div
        class="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center rounded-lg pointer-events-none"
      >
        <Button
          size="sm"
          class="bg-white/10 text-white backdrop-blur-md group-hover:bg-black transition-all"
          >Change Image</Button
        >
      </div>
    </div>
  </VDropdown>
</template>
