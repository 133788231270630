<script lang="ts" setup>
import { BubbleMenu } from "@tiptap/vue-3";
import type { Editor } from "@tiptap/vue-3";

const props = defineProps<{
  editor: Editor;
}>();

const route = useRoute();
const id = computed(() =>
  Array.isArray(route.params.id) ? undefined : route.params.id
);
const store = useFormStore();
const form = computed(() => store.forms[id.value!]);
const theme = computed(() => form.value?.theme);

const urlInputActive = ref(false);
const urlInput = ref("");
const appendToTarget = computed(() =>
  typeof document !== "undefined" ? document.body : "parent"
);

const urlInputRef = ref<HTMLInputElement | null>(null);

const activateLink = () => {
  if (!props.editor) return;
  urlInputActive.value = true;
  urlInput.value = props.editor.getAttributes("link").href;
  nextTick(() => {
    urlInputRef.value?.focus();
  });
};

const saveUrl = () => {
  if (urlInput.value) {
    props.editor.chain().focus().setLink({ href: urlInput.value }).run();
  } else {
    props.editor.chain().focus().unsetLink().run();
  }
  urlInputActive.value = false;
};
</script>

<template>
  <BubbleMenu
    :editor="editor"
    :tippy-options="{
      delay: [0, 0],
      appendTo: appendToTarget,
    }"
  >
    <StyleVariableProvider v-if="theme" :theme="theme">
      <div
        :class="`
          bg-slate-950
          text-white
          text-normal
          rounded-md
          shadow-md
          p-2
          flex
          gap-2
          relative
          items-center
          leading-none

          after:absolute
          after:top-full
          after:left-1/2
          after:-translate-x-1/2
          after:w-2
          after:h-2
          after:border-4
          after:border-b-transparent
          after:border-l-transparent
          after:border-r-transparent
          after:border-slate-950
        `"
      >
        <template v-if="urlInputActive">
          <div class="flex gap-2 items-center">
            <Icon name="mdi:link" class="w-5 h-5 text-white mr-0.5" />
            <input
              type="text"
              ref="urlInputRef"
              v-model="urlInput"
              class="bg-transparent text-white text-sm border-1 border-transparent border-b-white outline-none placeholder:text-white/50"
              placeholder="https://..."
              @blur="saveUrl"
              @keydown.enter="saveUrl"
            />
          </div>
        </template>
        <template v-else>
          <button
            type="button"
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'text-fk-accent-lighter': editor.isActive('bold') }"
          >
            <Icon class="w-5 h-5" name="mdi:format-bold" />
          </button>
          <button
            type="button"
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'text-fk-accent-lighter': editor.isActive('italic') }"
          >
            <Icon class="w-5 h-5" name="mdi:format-italic" />
          </button>
          <button
            type="button"
            @click="activateLink"
            :class="{ 'text-fk-accent-lighter': editor.isActive('link') }"
          >
            <Icon class="w-5 h-5" name="mdi:link" />
          </button>
          <button
            v-if="editor.isActive('link')"
            type="button"
            @click="editor.chain().focus().unsetLink().run()"
          >
            <Icon class="w-5 h-5" name="mdi:link-off" />
          </button>
        </template>
      </div>
    </StyleVariableProvider>
  </BubbleMenu>
</template>
