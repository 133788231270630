<script setup lang="ts">
import { useFormKitNodeById } from "@formkit/vue";
import { inputSizes } from "~/assets/colorThemes";
import type { LayoutOptions } from "~/types";

const route = useRoute();
const id = computed(() =>
  Array.isArray(route.params.id) ? undefined : route.params.id
);
const store = useFormStore();
const form = computed(() => store.forms[id.value!]);
const theme = computed(() => form.value?.theme);

useFormKitNodeById("theme-manager-layout", (node) => {
  if (node.context && theme.value.layout) {
    node.input(theme.value.layout);
  }
  node.on("commit", ({ payload }) => {
    if (!theme.value) return;
    theme.value.layout = payload;
  });
});

const inputStyles = [
  {
    label: "Sharp",
    value: "square",
  },
  {
    label: "Filled",
    value: "underline",
  },
  {
    label: "Smooth",
    value: "rounded",
  },
  {
    label: "Pill",
    value: "pill",
  },
];

const inputSize = ref(theme.value ? theme.value.formInputSize : "md");
const inputSpacing = ref(theme.value ? theme.value.formInputSpacing : 1.2);
const inputStyle = ref(theme.value ? theme.value.formInputStyle : "rounded");

const inputSizeOptions = Object.keys(inputSizes);

watch(
  () => theme.value && theme.value.formBgTransparency,
  (newValue, oldValue) => {
    if (oldValue === 0 && newValue > 0) {
      theme.value.formBgBlur = true;
    }
  },
  { immediate: true }
);

watch(
  () => theme.value && theme.value.bgFilter,
  (newValue) => {
    if (newValue === "none") {
      theme.value.bgFilterIntensity = 100;
    }
  },
  { immediate: true }
);

watch(
  inputStyle,
  (value) => {
    if (!theme.value) return;
    theme.value.formInputStyle = value;
  },
  { immediate: true }
);

watch(
  inputSize,
  (value) => {
    if (!theme.value) return;
    theme.value.formInputSize = value;
  },
  { immediate: true }
);

watch(
  inputSpacing,
  (value) => {
    if (!theme.value) return;
    theme.value.formInputSpacing = value;
    document.documentElement.style.setProperty(
      "--fk-spacing-base",
      `${Math.max(0.4, Math.min(value * 0.4, 0.6))}em`
    );
  },
  { immediate: true }
);

watch(
  () => theme.value.formFontFamily,
  () => {
    theme.value.formFontSizeBoost = 0;
  }
);

const sliderClasses = {
  fill: "$remove:bg-blue-600 bg-pink-600",
  tooltip:
    "$remove:bg-blue-600 $remove:after:border-t-blue-600 bg-pink-600 after:border-t-pink-600",
};

const toggleButtonsClasses = {
  outer: "!mb-3",
  wrapper: "!mb-0",
  options: "!flex",
  option: "border border-slate-300 mr-1 last:mr-0 rounded-sm",
  input: `$reset flex w-full px-3 py-2 overflow-clip items-center justify-center rounded-sm aria-pressed:bg-pink-50 aria-pressed:text-pink-700 aria-pressed:ring-2 aria-pressed:ring-pink-600 aria-pressed:relative aria-pressed:z-10 transition-all duration-300`,
  help: "text-slate-400 ml-0.5 tracking-tight",
};

const toggleClasses = {
  track: "!min-w-7",
  thumb: "!w-3",
  outer: "!mb-0 ml-2",
  wrapper: "!mb-0",
  label: "text-slate-600 !font-semibold flex items-center gap-0.5",
};

const checkboxClasses = {
  decorator:
    "$remove:ring-blue-500 $remove:peer-checked:border-blue-600 $remove:peer-checked:text-blue-600 $remove:peer-checked:bg-blue-50 $remove:w-[1em] w-[1.1em] ring-pink-500 peer-checked:border-pink-600 peer-checked:text-pink-600 peer-checked:bg-pink-50",
};
</script>

<template>
  <div v-if="form" class="p-2 flex flex-col overflow-hidden">
    <Card theme="minimal">
      <CardHeader class="!px-4 !pt-3 !pb-0">
        <h3 class="text-base flex items-center font-semibold text-slate-600">
          <Icon
            name="fluent:layout-column-one-third-left-16-regular"
            class="h-5 w-5 mr-1"
          />
          Page Layout
        </h3>
      </CardHeader>
      <CardContent class="!px-4 !py-3">
        <EditorInputLayoutOptions
          input-id="theme-manager"
          button-width="w-[46px]"
          accent-color="pink"
          :value="form.theme.layout"
          @update:value="(value:LayoutOptions) => form.theme.layout = value"
        />
      </CardContent>
    </Card>

    <Card theme="minimal">
      <CardHeader class="relative !px-4 !pt-3 !pb-0">
        <div class="flex justify-between gap-2 cursor-default">
          <h3 class="text-base flex items-center font-semibold text-slate-600">
            <Icon
              name="material-symbols:verified-rounded"
              class="h-5 w-5 mr-1"
            />
            Branding
          </h3>
          <span
            class="text-[10px] px-2 py-0.5 bg-blue-100 text-blue-500 rounded my-auto"
            v-tooltip="{
              content: `<span class='block text-xs'>Professional plan required</span>`,
              html: true,
              distance: 8,
              delay: {
                show: 0,
                hide: 0,
              },
            }"
          >
            PRO
          </span>
        </div>
      </CardHeader>
      <CardContent class="!px-4 !py-3">
        <div class="">
          <EditorFancyFile
            class="mb-4"
            label="Drag & drop your logo here or click to select"
            @input="(filePath:string) => form.theme.logo = filePath"
          />
        </div>
        <FormKit
          type="toggle"
          v-model="form.theme.formKitBranding"
          label="Show FormKit branding"
          :classes="toggleClasses"
        />
      </CardContent>
    </Card>

    <Card theme="minimal">
      <CardHeader class="!px-4 !pt-3 !pb-0">
        <div class="flex w-full justify-between items-center gap-2">
          <h3 class="text-base flex items-center font-semibold text-slate-600">
            <Icon name="heroicons-outline:photograph" class="h-5 w-5 mr-1" />
            Background Image
          </h3>
          <FormKit
            name="backgroundImage"
            type="toggle"
            v-model="form.theme.bgImageEnabled"
            :classes="toggleClasses"
            :inner-class="`!ml-auto !mr-0`"
          />
        </div>
      </CardHeader>
      <CardContent
        :class="`${
          form.theme.bgImageEnabled ? '!px-4 !py-3' : '!py-1.5'
        } transition-all`"
      >
        <div v-if="form.theme.bgImageEnabled" v-auto-animate>
          <EditorThemeImagePicker :form="form" />
          <EditorThemeFilterPicker />
          <FormKit
            v-if="form.theme.bgFilter !== 'none'"
            type="slider"
            v-model="form.theme.bgFilterIntensity"
            label="Filter intensity"
            :classes="sliderClasses"
            min="50"
            max="100"
            value="100"
            :delay="0"
            suffix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M3 3v18h18V3'/></svg>"
            prefix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 32 32'><path fill='currentColor' d='M6 6h4v4H6zm4 4h4v4h-4zm4-4h4v4h-4zm8 0h4v4h-4zM6 14h4v4H6zm8 0h4v4h-4zm8 0h4v4h-4zM6 22h4v4H6zm8 0h4v4h-4zm8 0h4v4h-4zm-4-12h4v4h-4zm-8 8h4v4h-4zm8 0h4v4h-4z'/></svg>"
            :tooltip-format="(value) => `${value}%`"
            :marks="[{ at: 50 }, { at: 75 }, { at: 100 }]"
          />
          <FormKit
            type="slider"
            v-model="form.theme.bgImageBrightness"
            label="Image Brightness"
            min="20"
            max="180"
            step="10"
            :delay="0"
            :classes="sliderClasses"
            prefix-icon="<svg width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22' /></svg>"
            suffix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M12 4c4.41 0 8 3.59 8 8s-3.59 8-8 8s-8-3.59-8-8s3.59-8 8-8m0-2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2'/></svg>"
            :tooltip-format="(value) => `${value}%`"
            :intervals="[
              { value: 20, step: 5 },
              { value: 40, step: 10 },
              { value: 160, step: 5 },
            ]"
            :marks="[{ at: 100 }]"
          />
          <FormKit
            type="slider"
            v-model="form.theme.bgImageTransparency"
            label="Image Transparency"
            :classes="sliderClasses"
            min="0"
            max="95"
            :delay="0"
            prefix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='M3 3v18h18V3'/></svg>"
            suffix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 32 32'><path fill='currentColor' d='M6 6h4v4H6zm4 4h4v4h-4zm4-4h4v4h-4zm8 0h4v4h-4zM6 14h4v4H6zm8 0h4v4h-4zm8 0h4v4h-4zM6 22h4v4H6zm8 0h4v4h-4zm8 0h4v4h-4zm-4-12h4v4h-4zm-8 8h4v4h-4zm8 0h4v4h-4z'/></svg>"
            :marks="[{ at: 0 }, { at: 25 }, { at: 50 }, { at: 75 }]"
          />
        </div>
      </CardContent>
    </Card>

    <Card theme="minimal">
      <CardHeader class="!px-4 !pt-3 !pb-0">
        <h3 class="text-base flex items-center font-semibold text-slate-600">
          <Icon name="icon-park-solid:color-filter" class="h-5 w-5 mr-1" />
          Colors & Presentation
        </h3>
      </CardHeader>
      <CardContent class="!px-4 !py-3" v-auto-animate>
        <EditorThemeColorPicker />
      </CardContent>
    </Card>

    <Card theme="minimal">
      <CardHeader class="!px-4 !pt-3 !pb-0">
        <h3 class="text-base flex items-center font-semibold text-slate-600">
          <Icon name="mdi:form-outline" class="h-5 w-5 mr-1" />
          Input Styles
        </h3>
      </CardHeader>
      <CardContent class="!px-4 !py-3" v-auto-animate>
        <FormKit
          type="slider"
          v-model="form.theme.formMaxWidth"
          :classes="sliderClasses"
          label="Maximum Form Width"
          min="400"
          max="800"
          step="20"
          :delay="0"
          prefix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12h7L7 9m0 6l3-3m11 0h-7l3-3m0 6l-3-3M9 6V5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v1M9 18v1a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-1'/></svg>"
          suffix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10 12H3l3-3m0 6l-3-3m11 0h7l-3-3m0 6l3-3M3 6V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v1M3 18v1a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-1'/></svg>"
        />
        <FormKit
          type="togglebuttons"
          label="Input Style"
          v-model="inputStyle"
          enforced
          :classes="toggleButtonsClasses"
          :options="inputStyles"
        />
        <FormKit
          type="togglebuttons"
          label="Input Size"
          v-model="inputSize"
          enforced
          :classes="toggleButtonsClasses"
          input-class="!px-2.5"
          :options="inputSizeOptions"
        />

        <FormKit
          type="slider"
          label="Input Spacing"
          v-model="inputSpacing"
          :classes="sliderClasses"
          :min="0.5"
          :max="4"
          :step="0.1"
          :delay="0"
          prefix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 12h7L7 9m0 6l3-3m11 0h-7l3-3m0 6l-3-3M9 6V5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v1M9 18v1a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-1'/></svg>"
          suffix-icon="<svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10 12H3l3-3m0 6l-3-3m11 0h7l-3-3m0 6l3-3M3 6V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v1M3 18v1a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-1'/></svg>"
        />
      </CardContent>
    </Card>

    <Card theme="minimal">
      <CardHeader class="!px-4 !pt-3 !pb-0">
        <h3 class="text-base flex items-center font-semibold text-slate-600">
          <Icon name="iconoir:text-box" class="h-5 w-5 mr-1" />
          Font Family
        </h3>
      </CardHeader>
      <CardContent class="!px-4 !py-3" v-auto-animate>
        <EditorThemeFontPicker :form="form" />

        <FormKit
          v-if="theme.formFontFamily"
          type="slider"
          label="Custom Font Family - Size Boost"
          help="This will increase the global font size. Useful if your custom font is smaller than the default."
          value="0"
          v-model="theme.formFontSizeBoost"
          :classes="sliderClasses"
          :min="0"
          :max="0.5"
          :step="0.01"
          :delay="0"
        />
      </CardContent>
    </Card>
  </div>
</template>
