<script lang="ts" setup>
import type { rootClasses } from "~/formkit.theme";
import MiniChat from "./MiniChat.vue";
import { token } from "@formkit/utils";

const props = defineProps<{
  idx: string;
}>();

const form = useCurrentForm();
const input = computed(() =>
  form.value?.schema ? getByIdx(form.value.schema, props.idx) : null
);
const emit = defineEmits<{
  (e: "hide"): void;
}>();
onMounted(() => {
  if (input.value?.suggestions) {
  }
});
</script>

<template>
  <div class="flex items-start justify-end pointer-events-none">
    <div
      v-if="form"
      class="group dialog-contents bg-white border border-slate-300 w-72 z-105 rounded-sm rounded-tr-none shadow-xl pointer-events-auto"
      data-showing-editor="true"
    >
      <StyleVariableProvider
        :theme="form.theme"
        base-spacing="0.25em"
        font-size="14px"
        class="w-full"
      >
        <FormKit type="ogGroup" ignore>
          <div class="w-full">
            <EditorPropsType :input="input" v-if="input" />
            <MiniChat
              v-if="input"
              :input="input"
              :initial="[
                {
                  content: 'How do you want to change this question?',
                  role: 'assistant',
                  id: token(),
                },
              ]"
            />
            <div class="relative text-center mx-2">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-slate-300"></div>
              </div>
              <div class="relative">
                <span class="bg-white px-2 text-slate-500 text-sm">OR</span>
              </div>
            </div>
            <EditorInputProps :input="input" />
          </div>
        </FormKit>
      </StyleVariableProvider>
    </div>
  </div>
</template>

<style scoped></style>
