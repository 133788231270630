<script setup lang="ts">
const route = useRoute();
const id = computed(() =>
  Array.isArray(route.params.id) ? undefined : route.params.id
);
const store = useFormStore();
const form = computed(() => store.forms[id.value!]);

const imageLoaded = ref(false);

const dropdownClasses = {
  inner: `$remove:focus-within:!ring-blue-500 $remove:focus-within:!border-blue-500 focus-within:!ring-pink-500 focus-within:!border-pink-500`,
  listbox: "grid grid-cols-3 p-1",
  listitem:
    "$reset relative rounded data-[is-active=true]:ring data-[is-active=true]:ring-pink-500 data-[is-active=true]:bg-pink-50  cursor-pointer",
  selectedIcon: "$reset hidden",
};

const bgFilterIntensity = computed(() => {
  if (typeof form.value.theme?.bgFilterIntensity === "number") {
    return form.value.theme.bgFilterIntensity / 100;
  }
  return 1;
});

const handleImageLoaded = () => {
  imageLoaded.value = true;
};

watch(
  () => form.value.theme && form.value.theme.bgImageUrl,
  () => {
    imageLoaded.value = false;
  }
);
</script>

<template>
  <FormKit
    type="dropdown"
    v-model="form.theme.bgFilter"
    label="Filter"
    id="image-filters"
    :classes="dropdownClasses"
    :deselect="false"
    :options="[
      {
        label: 'None',
        value: 'none',
      },
      {
        label: '1995',
        value: '1995',
      },
      {
        label: 'Contra',
        value: 'contra',
      },
      {
        label: 'Denim',
        value: 'denim',
      },
      {
        label: 'Glam',
        value: 'glam',
      },
      {
        label: 'Latte',
        value: 'latte',
      },
      {
        label: 'Lunar',
        value: 'lunar',
      },
      {
        label: 'Mayer',
        value: 'mayer',
      },
      {
        label: 'Noir',
        value: 'noir',
      },
      {
        label: 'Nostalgia',
        value: 'nostalgia',
      },
      {
        label: 'Quill',
        value: 'quill',
      },
      {
        label: 'Ramona',
        value: 'ramona',
      },
      {
        label: 'Sudo',
        value: 'sudo',
      },
      {
        label: 'Toast',
        value: 'toast',
      },
      {
        label: 'Victoria',
        value: 'victoria',
      },
      {
        label: 'Whiskey',
        value: 'whiskey',
      },
    ]"
  >
    <template #selection="{ option, value }">
      <div
        class="group w-full flex items-center"
        :data-active="option.value === value"
      >
        <div class="relative mr-2 w-16 aspect-video rounded overflow-hidden">
          <div
            :class="`!absolute z-20 top-0 left-0 w-full h-full fk-filter-${option.value}`"
            :style="`opacity: ${bgFilterIntensity};`"
          >
            <img
              :key="form.theme.bgImageThumbUrl"
              :src="form.theme.bgImageThumbUrl"
              class="relative z-10 object-cover object-center"
              loading="lazy"
              :onload="handleImageLoaded"
            />
          </div>
          <img
            :key="form.theme.bgImageThumbUrl"
            :src="form.theme.bgImageThumbUrl"
            loading="lazy"
            class="relative z-10 object-cover object-center"
          />
          <BlurHash
            v-if="!imageLoaded"
            class="!absolute z-10 inset-0"
            :hash="form.theme && form.theme.bgImageBlurHash"
          />
        </div>
        <div class="leading-tight text-center my-0.5">
          {{ option.label }}
        </div>
      </div>
    </template>

    <template #option="{ option, value }">
      <div
        class="group w-full flex flex-col items-center p-1"
        :data-active="option.value === value"
      >
        <div class="relative w-16 aspect-video rounded overflow-hidden">
          <div
            :class="`!absolute z-20 top-0 left-0 w-full h-full fk-filter-${option.value}`"
            :style="`opacity: ${bgFilterIntensity};`"
          >
            <img
              :key="form.theme.bgImageThumbUrl"
              :src="form.theme.bgImageThumbUrl"
              class="relative z-10 object-cover object-center"
              loading="lazy"
              :onload="handleImageLoaded"
            />
          </div>
          <img
            :key="form.theme.bgImageThumbUrl"
            :src="form.theme.bgImageThumbUrl"
            loading="lazy"
            class="relative z-10 object-cover object-center"
          />
          <BlurHash
            v-if="!imageLoaded"
            class="!absolute z-10 inset-0"
            :hash="form.theme && form.theme.bgImageBlurHash"
          />
        </div>
        <div
          class="text-[11px] text-slate-500 leading-tight text-center mt-0.5"
        >
          {{ option.label }}
        </div>
      </div>
    </template>
  </FormKit>
</template>
