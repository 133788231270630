<script lang="ts" setup>
import type { FormStore } from "@/types";
import gsap from "gsap";

definePageMeta({
  layout: "editor",
});

const route = useRoute();
const store = useFormStore();
const system = useSystemStore();
const { editorHistoryOpen, editorPreviewOpen, viewportWidth } =
  storeToRefs(system);
const id = computed(() => {
  if (Array.isArray(route.params.id)) return;
  return route.params.id;
});
const form = computed<FormStore | undefined>(() => store.forms[id.value!]);
const theme = computed(() => form.value?.theme!);
const delayedEditorPreviewOpen = ref(false);
const delayedEditorHistoryOpen = ref(false);

watch(editorPreviewOpen, () => {
  if (editorPreviewOpen.value) {
    delayedEditorPreviewOpen.value = true;
  }
  requestAnimationFrame(() => {
    if (editorPreviewOpen.value) {
      gsap.fromTo(
        "#preview-overlay",
        { opacity: 0, scale: 0.15 },
        {
          opacity: 1,
          scale: 1,
          duration: 0.5,
          delay: 0.25,
          ease: "elastic.out(0.08,0.10)",
          clearProps: "transform",
        }
      );

      if (viewportWidth.value > 1024) {
        gsap.fromTo(
          "#editor-sidebar",
          { opacity: 1, translateX: 0 },
          {
            opacity: 0,
            translateX: "-100%",
            duration: 0.35,
            ease: "power2.out",
            clearProps: "transform",
          }
        );
        gsap.fromTo(
          "#editor-canvas",
          { opacity: 1, translateX: 0 },
          {
            opacity: 0,
            translateX: "100%",
            duration: 0.3,
            ease: "power2.out",
            clearProps: "transform",
          }
        );
      } else {
        gsap.fromTo(
          "#editor-canvas",
          { opacity: 1 },
          { opacity: 0, duration: 0.3, ease: "power2.out" }
        );
        gsap.fromTo(
          "#editor-sidebar",
          { opacity: 1 },
          { opacity: 0, duration: 0.3, ease: "power2.out" }
        );
      }
    } else {
      gsap.fromTo(
        "#preview-overlay",
        { opacity: 1, scale: 1 },
        {
          opacity: 0,
          scale: 0.15,
          duration: 0.35,
          ease: "power2.out",
          onComplete: () => {
            delayedEditorPreviewOpen.value = false;
          },
          clearProps: "transform",
        }
      );

      if (viewportWidth.value > 1024) {
        gsap.fromTo(
          "#editor-sidebar",
          { opacity: 0, translateX: "-100%" },
          {
            opacity: 1,
            translateX: 0,
            duration: 0.35,
            delay: 0.15,
            ease: "power2.out",
            clearProps: "transform",
          }
        );
        gsap.fromTo(
          "#editor-canvas",
          { opacity: 0, translateX: "100%" },
          {
            opacity: 1,
            translateX: 0,
            duration: 0.3,
            delay: 0.15,
            ease: "power2.out",
            clearProps: "transform",
          }
        );
      } else {
        gsap.fromTo(
          "#editor-canvas",
          { opacity: 0 },
          { opacity: 1, duration: 0.3, ease: "power2.out" }
        );
        gsap.fromTo(
          "#editor-sidebar",
          { opacity: 0 },
          { opacity: 1, duration: 0.3, ease: "power2.out" }
        );
      }
    }
  });
});

watch(editorHistoryOpen, () => {
  if (editorHistoryOpen.value) {
    delayedEditorHistoryOpen.value = true;
  }

  requestAnimationFrame(() => {
    if (editorHistoryOpen.value) {
      gsap.fromTo(
        "#history-overlay",
        { translateY: "-100%" },
        {
          translateY: 0,
          duration: 0.35,
          ease: "power2.out",
          clearProps: "transform",
        }
      );
      gsap.fromTo(
        "#editor-container",
        { translateY: 0 },
        { translateY: "100%", duration: 0.35, ease: "power2.out" }
      );
    } else {
      gsap.fromTo(
        "#history-overlay",
        { translateY: 0 },
        {
          translateY: "-110%",
          duration: 0.35,
          ease: "power2.out",
          onComplete: () => {
            delayedEditorHistoryOpen.value = false;
          },
        }
      );
      gsap.fromTo(
        "#editor-container",
        { translateY: "100%" },
        { translateY: 0, duration: 0.35, ease: "power2.out" }
      );
    }
  });
});

onMounted(() => {
  if (id.value && !(id.value in store.forms) && !store.generatingForm) {
    store.loadForm(id.value!);
  }
});
</script>

<template>
  <div class="w-full">
    <ClientOnly>
      <EditorHistoryOverlay
        v-if="delayedEditorHistoryOpen"
        id="history-overlay"
      />
      <div
        class="flex h-full max-h-[calc(100dvh-50px)] bg-gray-200 diamond-crosshatch"
        id="editor-container"
      >
        <StyleVariableProvider
          v-if="theme"
          :theme="theme"
          class="flex-col lg:flex-row"
        >
          <EditorPreviewOverlay
            v-show="delayedEditorPreviewOpen"
            id="preview-overlay"
            :class="`
            absolute
            top-2
            left-2
            right-2
            bottom-2

          `"
          />
          <EditorSidebarContainer
            id="editor-sidebar"
            :class="`
            fixed
            top-[50px]
            left-0
            z-30
            max-w-[320px]
            max-h-[calc(100svh-50px)]
            order-2
            flex-col
            w-full
            shrink-0
            overflow-clip

            lg:relative
            lg:translate-x-0
            lg:top-0
            lg:max-h-full
            lg:mr-auto
            lg:order-1
            lg:shadow-none
            lg:border-r
            lg:border-r-gray-300
          `"
            :form-id="id!"
          />
          <EditorCanvas
            id="editor-canvas"
            :class="`
            order-1
            flex
            flex-col
            items-center
            w-full
            h-[calc(100svh-94px)]

            lg:flex-1
            lg:w-[calc(100svw-320px)]
            lg:h-full
            lg:flex-row
            lg:order-2
          `"
            :form-id="id!"
          />
        </StyleVariableProvider>
      </div>
    </ClientOnly>
  </div>
</template>
