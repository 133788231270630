<script lang="ts" setup>
import type { FormNode } from "@/types";

import { availableInputs } from "@/utils/groupedInputs";
import { FormKitIcon } from "@formkit/vue";
import { dragAndDrop } from "@/utils/drag-and-drop/vue";
import { insert } from "@/utils/drag-and-drop";
import { generateSuggestion } from "@/utils/form";
import type { GalleryHorizontal } from "lucide-vue-next";
import { token } from "@formkit/utils";

const route = useRoute();
const store = useFormStore();

const inputs = ref(availableInputs);
const query = ref("");

const id = computed(() =>
  Array.isArray(route.params.id) ? undefined : route.params.id
);

const form = computed(() => store.forms[id.value!]);

const filteredInputs = computed(() => {
  return inputs.value
    .map((group) => {
      const filteredOptions = group.options.filter(
        (input: Record<string, any>) => {
          return input.label.toLowerCase().includes(query.value.toLowerCase());
        }
      );
      return {
        ...group,
        options: filteredOptions,
      };
    })
    .filter((group) => group.options.length);
});

const colors: Record<string, Record<string, string>> = {
  green: {
    card: "hover:ring-1 ring-inset hover:ring-green-500 hover:bg-green-50/50",
    icon: "bg-green-500/90 border border-green-500/90",
  },
  teal: {
    card: "hover:ring-1 ring-inset hover:ring-teal-500/90 hover:bg-teal-50/50",
    icon: "bg-teal-500/90 border border-teal-500/90",
  },
  blue: {
    card: "hover:ring-1 ring-inset hover:ring-blue-500/90 hover:bg-blue-50/50",
    icon: "bg-blue-500/90 border border-blue-300",
  },
  indigo: {
    card: "hover:ring-1 ring-inset hover:ring-indigo-500/90 hover:bg-indigo-50/50",
    icon: "bg-indigo-500/90 border border-indigo-300",
  },
  purple: {
    card: "hover:ring-1 ring-inset hover:ring-purple-500/90 hover:bg-purple-50/50",
    icon: "bg-purple-500/90 border border-purple-300",
  },
  pink: {
    card: "hover:ring-1 ring-inset hover:ring-pink-500/90 hover:bg-pink-50/50",
    icon: "bg-pink-500/90 border border-pink-300",
  },
  red: {
    card: "hover:ring-1 ring-inset hover:ring-red-500/90 hover:bg-red-50/50",
    icon: "bg-red-500/90 border border-red-300",
  },
  orange: {
    card: "hover:ring-1 ring-inset hover:ring-orange-500/90 hover:bg-orange-50/50",
    icon: "bg-orange-500/90 border border-orange-300",
  },
  yellow: {
    card: "hover:ring-1 ring-inset hover:ring-yellow-500/90 hover:bg-yellow-50/50",
    icon: "bg-yellow-500/90 border border-yellow-500/90",
  },
  gray: {
    card: "hover:ring-1 ring-inset hover:ring-gray-500/90 hover:bg-gray-50/50",
    icon: "bg-gray-500/90 border border-gray-500/90",
  },
};

function createPlaceholder(inputName: string) {
  const placeholder = reactive({
    type: "placeholder",
    isInserted: true,
    inputType: inputName,
    name: token(),
    cols: "2",
  });

  return placeholder;
}

const mainParent = ref();

function initDnd(
  el: Element | ComponentPublicInstance | null,
  values: Array<any>
) {
  if (!(el instanceof HTMLElement)) return;

  dragAndDrop({
    parent: el,
    values,
    group: "formkit-builder",
    sortable: false,
    accepts: () => false,
    nativeDrag: false,
    plugins: [
      insert({
        insertPoint: () => {
          const div = document.createElement("div");

          for (const cls of insertPointClasses) div.classList.add(cls);

          return div;
        },
        dynamicValues: (data) => {
          const { value: inputType } = data.draggedNodes[0].data.value as {
            value: string;
          };
          const schemaToInject = createPlaceholder(inputType);
          generateSuggestion(form.value, schemaToInject);
          return [schemaToInject];
        },
      }),
    ],
  });
}
</script>

<template>
  <div class="relative pb-10 bg-gray-50/50" ref="mainParent">
    <div
      class="input-search sticky top-0 h-[2.5rem] z-20 bg-white border-b border-b-slate-200"
    >
      <FormKit
        v-model="query"
        type="search"
        prefix-icon="search"
        placeholder="Search inputs"
        inner-class="$reset flex items-center px-4 py-2 w-full"
      />
    </div>
    <div
      class="relative"
      v-for="group in filteredInputs"
      data-input-group="true"
      :key="group.group"
    >
      <h2
        class="top-[calc(2.5rem-1px)] text-sm font-semibold text-slate-600 pt-3.5 pb-1 px-2"
        v-if="'group' in group"
      >
        {{ group.group }}
      </h2>

      <div
        class="grid grid-cols-1 gap-2 p-2"
        :ref="(el) => initDnd(el, group.options)"
      >
        <div
          class="group relative flex items-center rounded select-none transition-colors cursor-grab hover:overflow-clip"
          :class="colors[group.color].card"
          v-for="input in group.options"
          :key="input.type"
          :title="input.attrs?.help || 'title'"
        >
          <div
            class="aspect-square flex items-center justify-center p-[7px] rounded text-white/90 w-8 mr-2 shrink-0 [&_svg]:w-full group-hover:!border-y-transparent group-hover:!border-l-transparent group-hover:rounded-none"
            :class="colors[group.color].icon"
          >
            <FormKitIcon :icon="`input_${input.value}`" />
          </div>
          <div>
            <p class="text-[13px] text-gray-500 text-center leading-tight">
              {{ input.label }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
