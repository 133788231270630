import { whenAvailable } from "@formkit/utils";

export const useUseChildElements = (parentId: string) => {
  const children = ref<Element[]>([]);
  let observer: MutationObserver;

  whenAvailable(parentId, (parent) => {
    const updateChildren = () => {
      if (parent) {
        children.value = Array.from(parent.children);
      }
    };

    observer = new MutationObserver(updateChildren);

    if (parent) {
      observer.observe(parent, { childList: true });
      updateChildren(); // Initial population of the array
    }
  });

  onUnmounted(() => {
    if (observer) {
      observer.disconnect();
    }
  });

  return children;
};
