<script lang="ts" setup>
import type { FormKitNode } from "@formkit/core";
import type { FormStore } from "~/types";
import { Editor } from "@tiptap/vue-3";
import { whenAvailable, token } from "@formkit/utils";

const props = defineProps<{
  formId: string;
}>();

const router = useRouter();
const store = useFormStore();
const system = useSystemStore();
const form = computed<FormStore | undefined>(() => store.forms[props.formId]);

const schemaPages = computed(() => {
  return form.value?.schemaPages;
});

const theme = computed(() => form.value?.theme);
const { editorActivePageId } = storeToRefs(system);

const pages = useUseChildElements("page-scroller");

function updateActivePageId(scroller: Element, target?: string) {
  if (typeof target !== "string") {
    target = "";
  }
  const isLargeScreen = scroller.scrollHeight > scroller.clientHeight; // Vertical overflow indicates large screen
  const scrollPosition = isLargeScreen
    ? scroller.scrollTop
    : scroller.scrollLeft;
  const scrollerSize = isLargeScreen
    ? scroller.clientHeight
    : scroller.clientWidth;
  for (let i = 0; i < pages.value.length; i++) {
    const page = pages.value[i] as HTMLElement;
    const pageStart = isLargeScreen
      ? page.offsetTop - (scroller as HTMLElement).offsetTop
      : page.offsetLeft - (scroller as HTMLElement).offsetLeft;
    const pageEnd =
      pageStart + (isLargeScreen ? page.offsetHeight : page.offsetWidth);
    // Calculate the visible size of the page
    const visibleSize =
      Math.min(pageEnd, scrollPosition + scrollerSize) -
      Math.max(pageStart, scrollPosition);
    // Check if more than 50% of the page is visible
    if (
      visibleSize >
      (isLargeScreen ? page.offsetHeight : page.offsetWidth) * 0.5
    ) {
      editorActivePageId.value = target || page.id;
      break;
    }
  }

  // Special case for the last page when it's shorter than 50% of the view area
  const lastPage = pages.value[pages.value.length - 1] as HTMLElement;
  if (lastPage) {
    const lastPageStart = isLargeScreen
      ? lastPage.offsetTop - (scroller as HTMLElement).offsetTop
      : lastPage.offsetLeft - (scroller as HTMLElement).offsetLeft;
    const lastPageEnd =
      lastPageStart +
      (isLargeScreen ? lastPage.offsetHeight : lastPage.offsetWidth);
    const visibleSize =
      Math.min(lastPageEnd, scrollPosition + scrollerSize) -
      Math.max(lastPageStart, scrollPosition);
    if (
      visibleSize >
      (isLargeScreen ? lastPage.offsetHeight : lastPage.offsetWidth) * 0.5
    ) {
      editorActivePageId.value = lastPage.id;
    }
  }
}

let boundScrollEffect: () => void;

onMounted(() => {
  whenAvailable("page-scroller", (scroller) => {
    boundScrollEffect = updateActivePageId.bind(null, scroller);
    scroller.addEventListener("scroll", boundScrollEffect);

    // default to page 1
    editorActivePageId.value = "form-page-1";
    // check if we have a url hash and if we do,
    // use it to override the default active page id
    if (router.currentRoute.value.hash) {
      editorActivePageId.value = router.currentRoute.value.hash.slice(1);
    }

    updateActivePageId(scroller, editorActivePageId.value);

    watchEffect(() => {
      const hash = useRoute().hash;
      if (scroller && hash) {
        const targetElement = document.getElementById(hash.slice(1));
        if (targetElement) {
          const isLargeScreen = scroller.scrollHeight > scroller.clientHeight;
          // Calculate the scroll position
          const scrollOffset = isLargeScreen
            ? targetElement.offsetTop - (scroller as HTMLElement).offsetTop - 55
            : targetElement.offsetLeft - (scroller as HTMLElement).offsetLeft;
          // Scroll the container
          if (isLargeScreen) {
            scroller.scrollTop = scrollOffset;
          } else {
            scroller.scrollLeft = scrollOffset;
          }
        }
      }
    });
  });
});

onUnmounted(() => {
  const scroller = document.getElementById("page-scroller");
  if (scroller && boundScrollEffect) {
    scroller.removeEventListener("scroll", boundScrollEffect);
  }
});

function updateSchemaProperty(node: FormKitNode) {
  node.on("updateSchemaKey", ({ payload }) => {
    form.value?.updateSchemaProperty(
      payload.idx,
      payload.property,
      payload.value
    );
  });
  return false;
}

const activeEditors = ref(new Set<Editor>());
const editorInitialized = ref(false);
function activeEditorListener(node: FormKitNode) {
  node.on("editorFocus.deep", ({ payload }) => {
    activeEditors.value.add(payload);
    editorInitialized.value = true;

    nextTick(() => {
      // gives time for previous editor to clean up
      // before reducing the set to the current editor
      activeEditors.value.clear();
      activeEditors.value.add(payload);
    });
  });
  return false;
}
</script>

<template>
  <div id="editor-canvas" class="relative">
    <ElementEditorControls />

    <EditorPagination
      :class="`
          order-2
          lg:order-1
          lg:absolute
          lg:block
          lg:top-1/2
          lg:left-1
          xl:left-2
          lg:-translate-y-1/2
          z-10
          my-1
          lg:my-0

          !mx-auto
          !lg:mx-0
        `"
      :active-page-id="editorActivePageId"
      :pages="form?.pages"
    />
    <EditorFormKitConfigProvider>
      <FormKit
        type="ogGroup"
        name="editor_root"
        id="editor_root"
        :plugins="[editMode, updateSchemaProperty, activeEditorListener]"
        v-slot="{ value: formData }"
      >
        <!-- <LogicEditor /> -->
        <EditorPageScroller
          v-if="form?.pages.length"
          class="order-1 lg:order-2 pb-1.5 -mt-1.5 lg:mt-0 lg:pb-0 pl-2 lg:pl-10 pr-2 pt-5"
        >
          <EditorBubbleMenu
            v-for="editor in activeEditors"
            :editor="editor"
            :key="editor.options.element.id"
          />

          <EditorPageContainer id="cover-page">
            <template #name>
              <Icon class="block w-4 h-4 mr-1" name="mdi:home-outline" />
              <span>Cover Page</span>
            </template>
            <template #default>
              <EditorPage :theme="theme">
                <h1 class="text-3xl text-slate-700 text-center">Cover Page</h1>
              </EditorPage>
            </template>
          </EditorPageContainer>

          <EditorPageContainer
            v-if="form?.pages"
            v-for="(page, index) in form.pages"
            :id="`form-page-${index + 1}`"
            :key="page.id"
          >
            <template #name>
              <Icon
                class="block w-4 h-4 mr-1"
                name="mdi:file-document-box-outline"
              />
              <span>{{ page.title }}</span>
            </template>
            <template #default>
              <EditorPage
                :theme="theme"
                :page="page"
                :page-index="index"
                :schema-pages="schemaPages"
              >
                <FormKitSchema
                  :data="{
                    formData,
                    __pageId: page.id,
                    __pageSchema: page.schema,
                  }"
                  :schema="page.schema"
                />
              </EditorPage>
            </template>
          </EditorPageContainer>

          <EditorPageContainer :required="true" id="confirmation-page">
            <template #name>
              <Icon class="block w-4 h-4 mr-1" name="mdi:done-outline" />
              <span>End</span>
            </template>
            <template #default>
              <EditorPage :theme="theme">
                <h1 class="text-3xl text-slate-700 text-center">
                  Confirmation Page
                </h1>
              </EditorPage>
            </template>
          </EditorPageContainer>
        </EditorPageScroller>
      </FormKit>
    </EditorFormKitConfigProvider>
  </div>
</template>
