import { defineAsyncComponent } from "vue";
import type { ItemType } from "~/prompts/inputs";

const baseProps = [
  {
    name: "label",
    idx: "1",
  },
  {
    name: "help",
    idx: "1.2",
  },
  {
    name: "value",
    idx: "4",
  },
  {
    name: "validation",
    idx: "3",
  },
];

const placeholder = {
  name: "placeholder",
  idx: "1.1",
  delta: [0, 0.01],
};

const options = {
  name: "options",
  idx: "2",
};

const multiple = {
  name: "multiple",
  idx: "1.3",
};

const minMax = {
  name: "minMax",
  idx: "1.4",
};

export const editorMap: Record<
  ItemType,
  Array<{ name: string; idx: string; delta?: [number, number] }>
> = {
  // Text inputs:
  text: [...baseProps, placeholder],
  tel: [...baseProps, placeholder],
  mask: [...baseProps, placeholder, { name: "mask", idx: "1.5" }],
  textarea: [...baseProps, placeholder],
  number: [...baseProps, placeholder, minMax],
  currency: [...baseProps, placeholder, minMax],
  rating: [...baseProps, minMax],
  slider: [...baseProps, minMax, multiple],
  // Options:
  dropdown: [...baseProps, options, placeholder, multiple, minMax],
  autocomplete: [...baseProps, options, placeholder, multiple, minMax],
  taglist: [...baseProps, options, placeholder, minMax],
  radio: [...baseProps, options],
  checkboxsingle: [...baseProps, options],
  checkboxmultiple: [...baseProps, options],
  // Misc:
  toggle: [...baseProps],
  colorpicker: [...baseProps],
  datepicker: [...baseProps, placeholder],
  file: [...baseProps, placeholder, multiple],
  repeater: [...baseProps, minMax],
  submit: [...baseProps],
};

export { validationMap } from "../prompts/inputs/props/validation";

/**
 * All the components for the editor mapped to their props.
 */
export const propComponents: Record<
  string,
  ReturnType<typeof defineAsyncComponent>
> = {
  type: defineAsyncComponent(
    () => import("~/components/editor/props/Type.vue")
  ),
  validation: defineAsyncComponent(
    () => import("~/components/editor/props/Validation.vue")
  ),
};
