<script setup lang="ts">
import type { FormKitSchemaNode } from "~/types";

defineProps<{
  activePageId: string;
  pages?: FormKitSchemaNode[][];
}>();

const { handleMouseOver, handleMouseOut } = useEditorScrollToggle();

const stableId = useId();
</script>

<template>
  <div
    class="pointer-events-none lg:pointer-events-auto items-center lg:items-start px-1 ml-1.5 xl:ml-4 bg-white border border-gray-300 m-auto rounded-full flex lg:flex-col"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    <div class="flex flex-col lg:py-1">
      <VTooltip
        :triggers="['hover']"
        :hideTriggers="['hover']"
        :placement="'right'"
        :distance="8"
        :delay="{
          show: 0,
          hide: 0,
        }"
        :aria-id="`${stableId}-cover`"
      >
        <template #popper>
          <div class="text-sm">Cover Page</div>
        </template>
        <a :href="`#cover-page`">
          <div
            class="text-gray-300 mx-auto lg:py-[3px] cursor-pointer hover:!text-pink-500 data-[active=true]:text-pink-500"
            :data-active="activePageId === `cover-page`"
          >
            <Icon
              class="w-5 h-4 mt-1 mx-auto hover:!text-pink-600 cursor-pointer"
              name="material-symbols:note"
            />
          </div>
        </a>
      </VTooltip>
    </div>

    <div
      class="flex lg:flex-col px-1 lg:px-0 lg:py-1"
      v-if="pages && pages.length > 0"
    >
      <VTooltip
        v-for="i in pages.length"
        :triggers="['hover']"
        :hideTriggers="['hover']"
        :placement="'right'"
        :distance="8"
        :delay="{
          show: 0,
          hide: 0,
        }"
        :aria-id="`${stableId}-${i}`"
      >
        <template #popper>
          <div class="text-sm">Page {{ i }}</div>
        </template>
        <a :href="`#form-page-${i}`">
          <div
            class="text-gray-300 mx-auto px-0.5 lg:px-1.5 lg:py-[3px] cursor-pointer hover:!text-pink-500 data-[active=true]:text-pink-500"
            :data-active="activePageId === `form-page-${i}`"
          >
            <div class="w-2 aspect-square rounded-full bg-current" />
          </div>
        </a>
      </VTooltip>
    </div>

    <div class="flex flex-col lg:pt-1">
      <VTooltip
        :triggers="['hover']"
        :hideTriggers="['hover']"
        :placement="'right'"
        :distance="8"
        :delay="{
          show: 0,
          hide: 0,
        }"
        :aria-id="`${stableId}-confirmation`"
      >
        <template #popper>
          <div class="text-sm">Confirmation page</div>
        </template>
        <a :href="`#confirmation-page`">
          <div
            class="text-gray-300 mx-auto py-[3px] cursor-pointer hover:!text-pink-500 data-[active=true]:text-pink-500"
            :data-active="activePageId === `confirmation-page`"
          >
            <Icon
              class="w-5 h-4 mt-1 mx-auto hover:!text-pink-600 cursor-pointer"
              name="material-symbols:check-circle"
            />
          </div>
        </a>
      </VTooltip>
    </div>
  </div>
</template>
