<script setup lang="ts">
const props = defineProps<{
  inputId: string;
  buttonWidth?: string;
  value?: string;
  accentColor?: string;
}>();

const { buttonWidth = "w-10", value = "minimal", accentColor = "blue" } = props;

// Do not remove this comment — it is to make sure Tailwind knows
// about the following classes:
// aria-pressed:ring-blue-300 bg-blue-100 border-blue-300 group-data-[active=true]:border-blue-300 group-data-[active=true]:bg-blue-100
// aria-pressed:ring-pink-300 border-pink-300 group-data-[active=true]:border-pink-300 group-data-[active=true]:bg-pink-100

const toggleButtonsClasses = {
  outer: "!mb-0",
  wrapper: "!mb-0",
  options: "!flex",
  option: "border border-slate-300 mr-1 last:mr-0 rounded-sm",
  input: `$reset flex ${buttonWidth} aspect-[5/4] overflow-clip items-center justify-center rounded-sm aria-pressed:bg-white aria-pressed:ring-2 aria-pressed:ring-${props.accentColor}-300 aria-pressed:relative aria-pressed:z-10 transition-all duration-300`,
  help: "text-slate-400 ml-0.5 tracking-tight",
};

const flowOptions = [
  {
    label: "All Questions on one page",
    shorthand: "All on one page",
    value: "single",
  },
  {
    label: "Questions split between multiple pages",
    shorthand: "Multiple questions per page",
    value: "multi",
  },
  {
    label: "Each question on its own page",
    shorthand: "One question per page",
    value: "max",
  },
];

function findOptionByValue(options: any[], value: string | undefined) {
  if (!value) return options[0];
  return options.find((option) => option.value === value);
}
</script>

<template>
  <FormKit
    :id="`${props.inputId}-layout`"
    name="flow"
    help="Flow:"
    label="Flow Format"
    type="togglebuttons"
    :classes="toggleButtonsClasses"
    enforced
    value="multi"
    :options="flowOptions"
  >
    <template #inputInner="{ option, value }">
      <div
        v-tooltip="{
          content: `<span class='block text-xs'>${option.label}</span>`,
          html: true,
          hideTriggers: ['hover'],
        }"
        class="relative w-full h-full"
      >
        <span class="group" :data-active="option.value === value">
          <template v-if="option.value === 'single'">
            <div
              class="absolute inset-0 bg-slate-200/30 group-data-[active=true]:bg-blue-100"
            >
              <div
                class="absolute inset-x-1 top-1.5 bottom-0 border-b-0 rounded-t-[2px] bg-white border group-data-[active=true]:bg-white group-data-[active=true]:border-blue-300 transition-all duration-300"
              />
            </div>
          </template>
          <template v-if="option.value === 'multi'">
            <div
              class="absolute inset-0 bg-slate-200/30 group-data-[active=true]:bg-blue-100"
            >
              <div
                class="absolute inset-x-1 h-3.5 top-0 border-t-0 rounded-b-[2px] bg-white border group-data-[active=true]:bg-white group-data-[active=true]:border-blue-300 transition-all duration-300"
              />
              <div
                class="absolute inset-x-1 h-3.5 bottom-0 border-b-0 rounded-t-[2px] bg-white border group-data-[active=true]:bg-white group-data-[active=true]:border-blue-300 transition-all duration-300"
              />
            </div>
          </template>
          <template v-if="option.value === 'max'">
            <div
              class="absolute inset-0 bg-slate-200/30 group-data-[active=true]:bg-blue-100"
            >
              <div
                class="absolute left-0 w-2 h-3.5 top-0 border-t-0 border-l-0 rounded-br-[2px] bg-white border group-data-[active=true]:bg-white group-data-[active=true]:border-blue-300 transition-all duration-300"
              />
              <div
                class="absolute right-0 w-7 h-3.5 top-0 border-t-0 border-r-0 rounded-bl-[2px] bg-white border group-data-[active=true]:bg-white group-data-[active=true]:border-blue-300 transition-all duration-300"
              />
              <div
                class="absolute left-0 w-7 h-3.5 bottom-0 border-b-0 border-l-0 rounded-tr-[2px] bg-white border group-data-[active=true]:bg-white group-data-[active=true]:border-blue-300 transition-all duration-300"
              />
              <div
                class="absolute right-0 w-2 h-3.5 bottom-0 border-b-0 border-r-0 rounded-tl-[2px] bg-white border group-data-[active=true]:bg-white group-data-[active=true]:border-blue-300 transition-all duration-300"
              />
            </div>
          </template>
        </span>
      </div>
    </template>

    <template #help="{ help, classes, value }">
      <span :class="classes.help">
        {{ findOptionByValue(flowOptions, value)?.shorthand }}</span
      >
    </template>
  </FormKit>
</template>
