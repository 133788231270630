<script lang="ts" setup>
import type { ItemType } from "~/prompts/inputs";
import type { FormNode } from "~/types";
const props = defineProps<{
  input?: FormNode | null;
}>();

const editableProps = computed(() => {
  const type: ItemType | undefined = props.input?.type;
  if (!type || !(type in editorMap)) return [];

  // Get the prop definitions for this input type
  const propDefs = editorMap[type];

  // Sort by idx and return the component for each prop
  return propDefs
    .sort((a, b) => parseFloat(a.idx) - parseFloat(b.idx))
    .filter((prop) => prop.name in propComponents)
    .map((prop) => ({
      name: prop.name,
      component: propComponents[prop.name],
    }));
});
</script>

<template>
  <div class="w-full p-2">
    <component
      v-for="prop in editableProps"
      :key="prop.name"
      :is="prop.component"
      :input="input"
    />
  </div>
</template>

<style></style>
