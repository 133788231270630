<script lang="ts" setup>
import { getNode, type FormKitNode } from "@formkit/core";
import { token } from "@formkit/utils";
import { FormKitIcon } from "@formkit/vue";
import type { ChatMessage, FormNode } from "~/types";

const props = defineProps<{
  input: FormNode;
  initial: ChatMessage | ChatMessage[];
}>();

const form = useCurrentForm();
const promptId = useId();
const loadingSuggestions = ref(false);

const messageHistory = ref<ChatMessage[]>(
  Array.isArray(props.initial) ? props.initial : [props.initial]
);

const messages = computed(() => messageHistory.value);

const handleSubmit = async (data: { prompt: string }, node: FormKitNode) => {
  node.reset();
  messageHistory.value.push({
    content: data.prompt,
    role: "user",
    id: token(),
  });
  const res = await $fetch<ReadableStream<Uint8Array>>(
    "/api/forms/" + form.value?.id + "/input",
    {
      method: "PUT",
      body: {
        input: props.input,
        form: form.value?.schema,
        prompt: data.prompt,
        history: messageHistory.value
          .filter((m) => m.role !== "suggestions")
          .map(({ role, content, id }) => ({ role, content, id })),
      },
      responseType: "stream",
    }
  );
  await readToolStream(res, {
    input: props.input,
    history: messageHistory,
  });

  if (promptId) {
    setTimeout(() => {
      document.getElementById(promptId)?.focus();
    }, 100);
  }
};

onMounted(async () => {
  const form = useCurrentForm();
  if (promptId) {
    setTimeout(() => {
      document.getElementById(promptId)?.focus();
    }, 100);
  }
  try {
    loadingSuggestions.value = true;
    const stream = await $fetch<ReadableStream<Uint8Array>>(
      "/api/forms/" + form.value?.id + "/edit-suggestions",
      {
        method: "POST",
        body: {
          input: props.input,
          form: form.value?.schema,
          history: messageHistory.value,
        },
        responseType: "stream",
      }
    );
    await readToolStream(stream, {
      input: props.input,
      history: messageHistory,
    });
  } catch (e) {
    console.error(e);
  } finally {
    loadingSuggestions.value = false;
  }
});

const handleSuggestion = (suggestion: string) => {
  if (promptId) {
    const node = getNode(promptId);
    if (node) {
      node.input(suggestion, false);
      node.submit();
    }
  }
};
</script>

<template>
  <div class="w-full col-span-2 p-2 text-xs">
    <div class="message-history min-h-[140px] relative">
      <div v-for="message in messages" :key="message.id" class="message mb-2">
        <div
          v-if="message.role === 'suggestions'"
          class="flex flex-wrap gap-1 items-center justify-center"
        >
          <button
            v-for="suggestion in message.content"
            type="button"
            :key="suggestion"
            class="cursor-pointer max-w-full mx-auto rounded-full px-2 py-0.5 bg-fuchsia-100 hover:bg-fuchsia-200 text-slate-600 text-center text-xs decoration-slate-400 whitespace-nowrap"
            @click="handleSuggestion(suggestion)"
          >
            {{ suggestion }}
          </button>
        </div>
        <div
          v-else
          :class="`
            flex
            ${message.role === 'user' ? 'justify-end' : 'justify-start'}
          `"
        >
          <div
            :class="`
              max-w-[80%]
              rounded-2xl
              ${
                message.role === 'user'
                  ? 'rounded-tr-sm bg-blue-100'
                  : `rounded-tl-sm ${
                      'error' in message ? 'bg-yellow-100' : 'bg-slate-100'
                    }`
              }
              px-4
              py-2
              text-slate-700
              flex items-center gap-2
            `"
          >
            <span v-if="'error' in message">
              <FormKitIcon icon="warning" class="inline-block w-4 h-4" />
            </span>
            {{ message.content }}
          </div>
        </div>
      </div>
      <div
        v-if="loadingSuggestions"
        class="absolute bottom-1 left-1/2 -translate-x-1/2 text-center text-xs text-slate-500"
      >
        <Loader />
      </div>
    </div>
    <FormKit
      type="form"
      :actions="false"
      @submit="handleSubmit"
      #default="{ state: { loading } }"
    >
      <div class="prompt-input">
        <FormKit
          type="text"
          name="prompt"
          outer-class="!mb-1"
          inner-class="$remove:rounded-fk $remove:px-fk-spacing-lg $remove:py-fk-spacing !py-0.5 !pl-4 pr-0.5 rounded-full"
          :id="promptId"
        >
          <template #suffix>
            <button
              class="text-white bg-black flex items-center w-6 h-6 p-1.5 rounded-full"
              type="submit"
            >
              <FormKitIcon
                v-if="!loading"
                icon="arrowUp"
                class="w-full block [&>svg]:block [&>svg]:w-full"
              />
              <Loader v-else />
            </button>
          </template>
        </FormKit>
      </div>
    </FormKit>
  </div>
</template>

<style></style>
