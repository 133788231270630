<script setup lang="ts">
import type { FormKitNode } from "@formkit/core";
import type { FormStore } from "@/types";

const props = defineProps<{
  form: FormStore;
}>();

const cleanLoad = ref(false);
const inputId = "font-search-input";
const inputElement = ref<HTMLInputElement | null>(null);
const currentPage = ref(1);
const isLoading = ref(false);
const fontSearchQuery = ref("");
const availableFonts = ref<any[]>([]);
const displayedFonts = ref<any[]>([]);
const itemsPerPage = 50;
const fontFilter = ref("all");

const filteredFonts = computed(() => {
  return availableFonts.value.filter((font) => {
    const nameMatch = font.family
      .toLowerCase()
      .includes(fontSearchQuery.value.toLowerCase());
    const categoryMatch =
      fontFilter.value === "all" || font.category === fontFilter.value;
    return nameMatch && categoryMatch;
  });
});

const toggleButtonsClasses = {
  outer: "!mb-2",
  wrapper: "!mb-0",
  options: "!flex",
  option: "border border-slate-300 mr-1 last:mr-0 rounded-sm",
  input: `$reset flex w-full px-3 py-2 overflow-clip items-center justify-center rounded-sm aria-pressed:bg-pink-50 aria-pressed:text-pink-700 aria-pressed:ring-2 aria-pressed:ring-pink-600 aria-pressed:relative aria-pressed:z-10 transition-all duration-300`,
  help: "text-slate-400 ml-0.5 tracking-tight",
};

const focusInput = () => {
  if (!inputElement.value) return;
  inputElement.value.focus();
  inputElement.value.select();
};

const searchInput = (node: FormKitNode) => {
  // autofocus input when it mounts
  setTimeout(() => {
    if (node && node.context) {
      inputElement.value = document.getElementById(inputId) as HTMLInputElement;
      if (inputElement.value) {
        focusInput();
      }
    }
  }, 10);
};

const useFont = (font: any) => {
  if (!props.form || !props.form.theme) return;
  props.form.theme.formFontFamily = font.family;
};

function handleSearch() {
  cleanLoad.value = true;
  currentPage.value = 1;
  loadNextPage();
}

function loadNextPage() {
  if (isLoading.value) return;
  isLoading.value = true;
  const startIndex = (currentPage.value - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const newFonts = filteredFonts.value.slice(startIndex, endIndex);

  if (cleanLoad.value) {
    displayedFonts.value = newFonts;
  } else {
    displayedFonts.value.push(...newFonts);
  }

  currentPage.value++;
  isLoading.value = false;
  cleanLoad.value = false;

  // Load fonts after updating displayedFonts
  loadFonts();
}

const handleScroll = (event: Event) => {
  const target = event.target as HTMLElement;
  if (target.scrollTop + target.clientHeight >= target.scrollHeight - 20) {
    loadNextPage();
  }
};

async function triggerLoadFonts() {
  try {
    availableFonts.value = await $fetch("/google-fonts/load", {
      method: "get",
      responseType: "json",
    });
    loadNextPage();
  } catch (error) {
    console.error("Error fetching Google Fonts:", error);
  }
}

watch([fontSearchQuery, fontFilter], () => {
  cleanLoad.value = true;
  currentPage.value = 1;
  loadNextPage();
});

const loadFonts = () => {
  displayedFonts.value.forEach((font) => {
    const link = document.createElement("link");
    link.href = `https://fonts.googleapis.com/css?family=${encodeURIComponent(
      font.family
    )}:regular,700&display=swap`;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  });
};
</script>

<template>
  <div class="relative w-full mb-4">
    <VDropdown :placement="'right-start'" :distance="8" :delay="0">
      <template #popper>
        <div
          class="min-w-[400px] w-full max-w-[600px] max-h-[min(1000px,92dvh)] min-h-[80vh] overflow-auto"
          @scroll="handleScroll"
        >
          <div class="p-4 sticky top-0 bg-white z-50 border-b border-slate-200">
            <FormKit
              :plugins="[searchInput]"
              :id="inputId"
              type="text"
              :disabled="isLoading"
              v-model="fontSearchQuery"
              suffix-icon="search"
              outer-class="!mb-0"
              placeholder="Search for a font"
              @suffix-icon-click="focusInput"
              @keypress.enter="handleSearch()"
            >
              <template #prefix>
                <Loader v-if="isLoading" class="mr-2" />
              </template>
            </FormKit>
            <FormKit
              type="togglebuttons"
              v-model="fontFilter"
              :classes="toggleButtonsClasses"
              :options="[
                { label: 'All', value: 'all' },
                { label: 'Serif', value: 'serif' },
                { label: 'Sans-serif', value: 'sans-serif' },
                { label: 'Display', value: 'display' },
                { label: 'Handwriting', value: 'handwriting' },
              ]"
              outer-class="mt-4"
            />
          </div>

          <div class="grid grid-cols-2 gap-1 px-4 py-4">
            <div
              v-for="font in displayedFonts"
              :key="font.family"
              class="relative p-4 border border-slate-200 cursor-pointer rounded hover:ring hover:ring-pink-500"
              v-close-popper
              @click="useFont(font)"
            >
              <h3
                class="text-xl font-bold text-slate-950"
                :style="{ fontFamily: font.family }"
              >
                {{ font.family }}
              </h3>
              <p class="text-sm text-slate-500">
                {{ font.category }}
              </p>
              <p
                class="text-base mt-2 text-slate-700"
                :style="{ fontFamily: font.family }"
              >
                Create sleek forms quickly with FormKit's vibrant, easy-to-use
                platform.
              </p>
            </div>
          </div>
          <div v-if="!availableFonts.length" class="flex justify-center py-4">
            <Loader />
          </div>
        </div>
      </template>

      <div
        class="relative w-full p-2 pr-10 border border-slate-200 rounded-lg cursor-pointer flex items-center"
        :style="{ fontFamily: form?.theme?.formFontFamily }"
        @click="triggerLoadFonts"
      >
        <span v-if="form?.theme?.formFontFamily">
          {{ form.theme.formFontFamily }}
        </span>
        <span v-else class="text-slate-400"> Choose a custom font </span>
        <button
          class="absolute flex w-6 aspect-square right-2 top-1/2 transform -translate-y-1/2 text-slate-400 hover:text-slate-600"
        >
          <Icon name="mdi:chevron-down" class="m-auto w-full h-full" />
        </button>
      </div>
    </VDropdown>

    <div v-if="form?.theme?.formFontFamily" class="mt-0.5 ml-1">
      <button
        @click="() => (form.theme.formFontFamily = '')"
        class="text-xs text-slate-500 hover:text-pink-600 transition-colors duration-200"
      >
        Remove font
      </button>
    </div>
  </div>
</template>
