<script setup lang="ts">
import type { FormKitSchemaNode } from "~/types";
import { dragAndDrop } from "~/utils/drag-and-drop/vue";
import { insert } from "~/utils/drag-and-drop";

import gsap from "gsap";
import { Flip } from "gsap/Flip";

const route = useRoute();
const id = computed(() =>
  Array.isArray(route.params.id) ? undefined : route.params.id
);
const pages = computed(() => form.value?.pages);
const stableId = useId();
const dndParent = ref(null);
const formStore = useFormStore();
const form = computed(() => formStore.forms[id.value!]);
const { generatingForm } = storeToRefs(formStore);
const formLoading = computed(() => form.value?.loading);

onMounted(() => {
  gsap.registerPlugin(Flip);

  if (formLoading.value === false && generatingForm.value === false) {
    if (!dndParent.value) return;
    initDnd(dndParent.value);
    return;
  }

  watch(
    [formLoading, generatingForm],
    ([newFormValue, newGeneratingFormValue]) => {
      if (newFormValue || newGeneratingFormValue) return;
      if (!dndParent.value) return;
      initDnd(dndParent.value);
    }
  );
});

const schema = computed(() => form.value?.schema || []);

const pagesWithBreaks = computed({
  get: () => {
    const pagesWithBreaks = [];

    for (const value of schema.value) {
      if (value.type === "page_break") {
        pagesWithBreaks.push([value]);
      } else {
        pagesWithBreaks[pagesWithBreaks.length - 1].push(value);
      }
    }

    return pagesWithBreaks;
  },
  set: (values) => {
    form.value.schema = values.flat();
  },
});

const animationState = ref<any>(null);
function initDnd(el: HTMLElement) {
  dragAndDrop({
    parent: el,
    values: pagesWithBreaks,
    group: "formkit-flow-manager",
    dragHandle: ".drag-handle",
    nativeDrag: false,
    draggable: (el) => {
      return (
        el.id !== "cover-page-preview" && el.id !== "confirmation-page-preview"
      );
    },
    plugins: [
      insert({
        insertPoint: () => {
          const div = document.createElement("div");

          for (const cls of insertPointClasses) div.classList.add(cls);

          return div;
        },
      }),
    ],
    onDragstart: () => {
      getAnimationState();
    },
  });
}

function getAnimationState() {
  const targets = document.querySelectorAll("#flow-manager .flow-manager-page");
  animationState.value = Flip.getState(targets);
}

watch(pages, () => {
  if (!animationState.value) {
    return;
  }
  requestAnimationFrame(() => {
    Flip.from(animationState.value, {
      duration: 0.35,
      ease: "elastic.out(0.11,0.10)",
      absolute: true,
      simple: true,
      clearProps: "transform",
      onComplete: () => {
        getAnimationState();
      },
    });
  });
});
</script>

<template>
  <div class="flex-col w-full mt-px overflow-auto" id="flow-manager">
    <div
      class="bg-white sticky top-0 z-10 py-2 px-5 flex items-center justify-end border-b border-b-slate-300"
    >
      <EditorInputFlowOptions input-id="flow-options" />
      <VDropdown
        :triggers="['hover']"
        :placement="'bottom'"
        :distance="8"
        :aria-id="stableId"
        :delay="{
          show: 0,
          hide: 0,
        }"
      >
        <template #popper>
          <div class="text-xs">
            Inserts a new page after the current active page
          </div>
        </template>
        <Button
          size="sm"
          class="!bg-white !text-slate-600 border border-slate-300 hover:!bg-gray-100 ml-auto"
        >
          <Icon class="mr-1.5 h-3 w-3 sm:h-4 sm:w-4" name="ph:plus" />
          <span class="text-xs sm:text-sm">Add Page</span>
        </Button>
      </VDropdown>
    </div>
    <div
      class="flex flex-col gap-3 pb-10 pt-3 px-5 w-full items-center"
      ref="dndParent"
    >
      <EditorFlowManagerPage
        :index="-1"
        key="cover-page"
        title="Cover Page"
        id="cover-page-preview"
      />

      <EditorFlowManagerPage
        v-for="(page, index) in pages"
        :key="page.id"
        :form="form"
        :page="page"
        :title="page.title"
        :index="index"
        :draggable="true"
      />

      <EditorFlowManagerPage
        key="confirmation-page"
        title="Confirmation Page"
        :index="(pages && pages.length + 1) || -2"
        id="confirmation-page-preview"
      />
    </div>
  </div>
</template>
