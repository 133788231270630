<script lang="ts" setup>
import { getNode } from "@formkit/core";
import { hideAllPoppers, recomputeAllPoppers } from "floating-vue";
import gsap from "gsap";
import { Flip } from "gsap/Flip";

const props = defineProps<{
  isInternal?: boolean;
}>();

const system = useSystemStore();
const { viewportWidth, activeElementControls } = storeToRefs(system);
const form = useCurrentForm();
const editorControlsWrapperEl = ref<HTMLElement | null>(null);
const editorControlsEl = ref<HTMLElement | null>(null);
const activeIdx = computed({
  get: () => form.value?.activeIdx,
  set: (value) => {
    if (form.value) form.value.activeIdx = value ?? "";
  },
});
const latestActiveIdx = ref("");
const editorIsOpen = computed({
  get: () => form.value?.inlineEditorIsOpen,
  set: (value) => {
    if (form.value) form.value.inlineEditorIsOpen = value ?? false;
  },
});
const editorIsFocused = computed({
  get: () => form.value?.inlineEditorIsFocused,
  set: (value) => {
    if (form.value) form.value.inlineEditorIsFocused = value ?? false;
  },
});
const position = ref({ x: 0, y: 0 });

watch(activeIdx, (newValue) => {
  if (editorIsOpen.value) return;
  hideAllPoppers();
  requestAnimationFrame(() => {
    latestActiveIdx.value = newValue ?? "";
    rafUpdatePosition();
  });
});
watch(viewportWidth, () => {
  rafUpdatePosition();
});

watch(editorIsOpen, () => {
  if (!editorIsOpen.value) {
    latestActiveIdx.value = activeIdx.value ?? "";
    rafUpdatePosition();
  }
});

function computePosition() {
  if (!latestActiveIdx.value) return;

  const elementEl = document.querySelector(
    `[data-idx="${latestActiveIdx.value}"]`
  );
  if (!elementEl) return;

  activeElementControls.value = elementEl as HTMLElement;

  const editorCanvas = document.querySelector("#editor-canvas");

  if (!editorCanvas) return;

  requestAnimationFrame(() => {
    const elementRect = elementEl.getBoundingClientRect();
    const canvasRect = editorCanvas.getBoundingClientRect();

    const x = elementRect.x - canvasRect.x;
    const y = elementRect.y - canvasRect.y;

    position.value = { x, y };
  });
}

function rafUpdatePosition() {
  requestAnimationFrame(() => {
    computePosition();
    recomputeAllPoppers();
    closeIfOffCanvas();
  });
}

function closeIfOffCanvas() {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const rect = editorControlsEl.value?.getBoundingClientRect();
  if (!rect) return;
  if (
    rect.bottom < 0 ||
    rect.top > viewportHeight ||
    rect.right < 0 ||
    rect.left > viewportWidth
  ) {
    closeDialog();
    requestAnimationFrame(() => {
      activeIdx.value = "";
    });
  }
}

function removeInput() {
  if (!latestActiveIdx.value) return;
  const elementEl = document.querySelector(
    `[data-idx="${latestActiveIdx.value}"]`
  );

  if (elementEl) {
    const closestPageBoundary =
      elementEl.closest(".page-scroller") || elementEl;
    const targets = closestPageBoundary.querySelectorAll(".formkit-outer");
    const state = Flip.getState(targets);

    requestAnimationFrame(() => {
      const idxToRemove = latestActiveIdx.value;
      hideAllPoppers();
      activeIdx.value = "";

      dissolveElement(elementEl as HTMLElement, () => {
        form.value?.removeByIndex(idxToRemove);
        nextTick(() => {
          Flip.from(state, {
            duration: 0.5,
            ease: "elastic.out(0.13,0.12)",
            absolute: true,
            absoluteOnLeave: true,
          });
        });
      });
    });
  }
}

function closeDialog() {
  showX.value = false;
  hideAllPoppers();
}

const showX = ref(false);

onMounted(() => {
  gsap.registerPlugin(Flip);

  window.addEventListener("scroll", rafUpdatePosition, true);

  if (props.isInternal) {
    setTimeout(() => {
      showX.value = true;
    }, 50);
  }
});

onUnmounted(() => {
  window.removeEventListener("scroll", rafUpdatePosition, true);
});
</script>

<template>
  <div
    v-show="activeIdx"
    ref="editorControlsWrapperEl"
    class="element-controls z-20 absolute group-[]/repeater:pr-1 transition-opacity duration-200 opacity-100"
    :style="{ top: `${position.y}px`, left: `${position.x}px` }"
    @mouseover="editorIsFocused = true"
    @mouseleave="editorIsFocused = false"
  >
    <div
      ref="editorControlsEl"
      class="absolute -top-[4px] -left-[14px] lg:-left-[12px] flex flex-col items-center gap-2 bg-white rounded border border-slate-300 group-data-[showing-editor]:opacity-0 group-data-[showing-editor]:delay-75 transition-all"
      :class="
        editorIsOpen
          ? 'border-l-transparent rounded-tl-none rounded-bl-none'
          : ''
      "
    >
      <VDropdown
        theme="editor"
        @show="editorIsOpen = true"
        @hide="editorIsOpen = false"
        :distance="0"
      >
        <button
          class="flex items-center text-slate-600 hover:text-slate-800 edit-options-trigger"
          type="button"
        >
          <EditIcon :show-x="editorIsOpen || false" />
        </button>
        <template #popper>
          <EditorInputDialog
            v-if="latestActiveIdx"
            :idx="latestActiveIdx"
            :key="latestActiveIdx"
            @hide="closeDialog"
          />
        </template>
      </VDropdown>

      <div id="drag-handle" class="drag-handle cursor-grab flex items-center">
        <Icon
          class="w-6 h-5 lg:w-4 lg:h-4 text-slate-600 hover:text-slate-800"
          name="icon-park-outline:drag"
        />
      </div>
      <button
        class="flex items-center pt-2 lg:pt-1 border-t border-slate-300"
        type="button"
        @click="removeInput"
      >
        <span class="sr-only">Remove</span>
        <Icon
          class="relative w-6 h-6 lg:w-5 lg:h-5 text-slate-600 hover:text-red-600 cursor-pointer"
          name="ph:trash-light"
        />
      </button>
    </div>
  </div>
</template>

<style></style>
