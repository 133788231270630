<script lang="ts" setup>
import { computed } from "vue";
import { availableInputs } from "@/utils/groupedInputs";
import type { FormNode } from "@/types";
import { FormKitIcon } from "@formkit/vue";
import { token } from "@formkit/utils";
import { usePropModel } from "~/composables/usePropModel";

const props = defineProps<{
  input: FormNode;
}>();
const model = usePropModel(props, "type", true, async () => {
  await nextTick();
  const trigger = document.querySelector(
    `[data-idx="${props.input.idx}"] .edit-options-trigger`
  );
  if (trigger) {
    trigger.dispatchEvent(new Event("click"));
    await nextTick();
    await delay(50);
    document.getElementById(`type-prop-${props.input.idx}`)?.focus();
  }
});

const group = computed(() => {
  // Find the group that contains the current input type
  const currentGroup = availableInputs.find((group) =>
    group.options.some((option) => option.value === props.input.type)
  );
  // Return options from the same group
  return currentGroup;
});
</script>

<template>
  <div class="w-full" v-if="group">
    <FormKit
      type="dropdown"
      :options="group.options"
      listitem-class="group/listitem !pl-5"
      v-model="model"
      outer-class="border-b border-slate-300 !mb-1"
      inner-class="$remove:border $remove:shadow-sm $remove:rounded-fk !outline-0 px-1 py-.5 border-slate-300"
      selector-class="$remove:justify-between"
      selection-wrapper-class="$remove:grow"
      :id="`type-prop-${props.input.idx}`"
    >
      <template #option="{ option }">
        <div class="flex items-center gap-2">
          <div
            class="aspect-square flex items-center justify-center p-[1px] bg-slate-200 text-slate-500 border border-slate-300 rounded w-4 shrink-0 [&_svg]:w-full group-data-[is-active=true]/listitem:bg-slate-50 group-data-[is-active=true]/listitem:ring-1 group-data-[is-active=true]/listitem:ring-slate-300"
          >
            <FormKitIcon :icon="`input_${option.value}`" />
          </div>
          <span>{{ option.label }}</span>
        </div>
      </template>
    </FormKit>
  </div>
</template>

<style></style>
