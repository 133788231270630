<script setup lang="ts">
const props = defineProps<{
  formId: string;
}>();

const store = useFormStore();
const system = useSystemStore();
const { editorActiveSidebarTab, commandPaletteOpen } = storeToRefs(system);
const form = computed(() => store.forms[props.formId]);
const tabs = [
  { id: "flow", name: "Flow", icon: "material-symbols:account-tree-outline" },
  { id: "inputs", name: "Content", icon: "lucide:grid-2x2" },
  { id: "theme", name: "Theme", icon: "ph:paint-brush" },
  { id: "chat", name: "Edit", icon: "ri:chat-smile-ai-line" },
];
const lastTab = ref<string | null>("flow");

const handleTabClick = (tabId: string) => {
  lastTab.value = tabId;
  if (window.innerWidth < 1024) {
    editorActiveSidebarTab.value =
      editorActiveSidebarTab.value === tabId ? null : tabId;
  } else {
    editorActiveSidebarTab.value = tabId;
    lastTab.value = tabId;
  }
};

const togglePromptOverlay = () => {
  if (!commandPaletteOpen.value) {
    commandPaletteOpen.value = true;
  }
};

onMounted(() => {
  if (window.innerWidth < 1024) {
    editorActiveSidebarTab.value = null;
  }
});
</script>

<template>
  <div class="pointer-events-none">
    <div
      id="editor-tabs"
      :class="`
        flex
        pointer-events-auto
        w-full
        fixed
        bottom-0
        left-0
        right-0
        z-20
        border-t
        border-t-gray-300

        lg:relative
        lg:bottom-auto
        lg:border-t-0
      `"
    >
      <template v-for="tab in tabs" :key="tab.id">
        <div
          v-if="tab.id !== 'chat'"
          :data-active="tab.id === editorActiveSidebarTab"
          @click="handleTabClick(tab.id)"
          :class="`
          group
          flex
          items-center
          justify-center
          gap-1

          pt-3
          pb-3
          px-2

          lg:pt-1.5
          lg:pb-2
          lg:px-1
          grow
          bg-gray-50
          border-t-[3px]
          border-t-transparent
          border-r
          border-b
          border-r-gray-300
          border-b-gray-300
          last:border-r-transparent
          cursor-pointer
          select-none
          hover:bg-gray-100

          data-[active=true]:bg-white
          data-[active=true]:border-b-white
          data-[active=true]:border-t-pink-500
        `"
        >
          <Icon
            class="h-4 w-4 text-slate-500 group-hover:text-pink-500"
            :name="tab.icon"
          />
          <span class="text-xs text-slate-600">{{ tab.name }}</span>
        </div>

        <div
          v-if="tab.id === 'chat'"
          :data-active="tab.id === editorActiveSidebarTab"
          v-tooltip="{
            content: `<span class='block text-xs leading-none'>Edit with chat</span>`,
            placement: 'right',
            delay: 100,
            html: true,
          }"
          @click="togglePromptOverlay()"
          :class="`
            group
            flex
            items-center
            justify-center
            gap-1

            pt-3
            pb-3
            px-1

            lg:pt-1.5
            lg:pb-2
            lg:px-0.5
            grow
            bg-gray-50
            border-t-[3px]
            border-t-transparent
            border-r
            border-b
            border-r-gray-300
            border-b-gray-300
            last:border-r-transparent
            cursor-pointer
            select-none
            hover:bg-gray-100

            data-[active=true]:bg-white
            data-[active=true]:border-b-white
            data-[active=true]:border-t-pink-500
          `"
        >
          <Icon
            class="h-4.5 w-4.5 text-slate-500 group-hover:text-pink-500"
            :name="tab.icon"
          />
        </div>
      </template>
    </div>

    <div
      :data-show="!!editorActiveSidebarTab"
      :class="`
        pointer-events-auto
        relative
        flex
        flex-col
        flex-1
        overflow-auto
        overscroll-contain
        bg-white
        border-r
        border-r-gray-300
        shadow-lg
        h-[calc(100dvh-94px)]
        transition-transform

        lg:h-full
        lg:shadow-none
        lg:border-r-0

        -translate-x-full
        data-[show=true]:translate-x-0
        lg:translate-x-0
      `"
    >
      <div class="flex flex-col flex-1">
        <EditorFlowManager
          v-show="lastTab === 'flow'"
          :form="form"
          key="flow"
        />
        <EditorInputsList
          v-show="lastTab === 'inputs'"
          :form="form"
          key="inputs"
        />
        <EditorThemeManager
          v-show="lastTab === 'theme'"
          :form="form"
          key="theme"
        />
      </div>
    </div>
  </div>
</template>
