<template>
  <div class="space-background">
    <canvas ref="starCanvas" class="star-canvas opacity-70"></canvas>
    <div
      class="nebula-background"
      :style="{ filter: `hue-rotate(${(maxIndex - activeIndex) * 2}deg)` }"
    >
      <img v-for="i in 3" :src="`/img/nebula-${i}.png`" class="blob" />
    </div>
  </div>
</template>

<script setup lang="ts">
interface Star {
  x: number;
  y: number;
  z: number;
}

const props = defineProps<{
  activeIndex: number;
  originX?: number;
  originY?: number;
}>();

const { activeIndex, originX, originY } = toRefs(props);

const computedOriginX = computed(() => originX.value ?? 50);
const computedOriginY = computed(() => originY.value ?? 50);

const starCanvas = ref<HTMLCanvasElement | null>(null);
const stars = ref<Star[]>([]);
const ctx = ref<CanvasRenderingContext2D | null>(null);
const animationId = ref<number | null>(null);
const targetZ = ref(0);
const currentZ = ref(0);
const easingFactor = 0.1;
const maxIndex = props.activeIndex;

const initStars = () => {
  const canvas = starCanvas.value!;
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  ctx.value = canvas.getContext("2d");

  const starCount = Math.floor((canvas.width * canvas.height) / 2500);
  stars.value = Array.from({ length: starCount }, () => ({
    x: Math.random() * canvas.width - canvas.width / 2,
    y: Math.random() * canvas.height - canvas.height / 2,
    z: Math.random() * 1000,
  }));
};

const moveStars = () => {
  const canvas = starCanvas.value!;
  const previousZ = currentZ.value;
  currentZ.value += (targetZ.value - currentZ.value) * easingFactor;
  const deltaZ = currentZ.value - previousZ;

  const autoAnimationSpeed = 0.05;
  const zIncrement = deltaZ - autoAnimationSpeed;

  stars.value.forEach((star) => {
    star.z += zIncrement;
    if (star.z <= 0 || star.z > 1000) {
      // Standardize star initialization
      star.z = (star.z + 1000) % 1000;
      star.x = Math.random() * canvas.width - canvas.width / 2;
      star.y = Math.random() * canvas.height - canvas.height / 2;
    }
  });
};

const drawStars = () => {
  const canvas = starCanvas.value!;
  const context = ctx.value!;

  // Clear the main canvas
  context.fillStyle = "rgba(0, 0, 0, 1)";
  context.fillRect(0, 0, canvas.width, canvas.height);

  const originXPos = (computedOriginX.value / 100) * canvas.width;
  const originYPos = (computedOriginY.value / 100) * canvas.height;

  // Set fillStyle once before the loop
  context.fillStyle = "white";

  stars.value.forEach((star) => {
    // Reduce redundant computations
    const invZ = 500 / star.z;
    const x = star.x * invZ + originXPos;
    const y = star.y * invZ + originYPos;
    const size = (1 - star.z / 1000) * 3;
    context.fillRect(x, y, size, size);
  });
};

const animate = () => {
  moveStars();
  drawStars();
  animationId.value = requestAnimationFrame(animate);
};

const handleResize = () => {
  initStars();
  drawStars();
};

onMounted(() => {
  initStars();
  drawStars();
  animationId.value = requestAnimationFrame(animate);

  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  if (animationId.value) {
    cancelAnimationFrame(animationId.value);
  }
  window.removeEventListener("resize", handleResize);
});

watch(activeIndex, (newIndex, oldIndex) => {
  const indexDifference = newIndex - oldIndex;
  targetZ.value += indexDifference * 10;
});
</script>

<style scoped>
.space-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}

.star-canvas {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.nebula-background {
  position: absolute;
  inset: 0;
  width: 150vw;
  height: 150vh;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -60%);
  overflow: hidden;
  opacity: 0.5;
}

@media (max-width: 767px) {
  .nebula-background {
    min-width: 800px;
    min-height: 1000px;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    filter: none;
    opacity: 0.6;
  }
}

.blob {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

/* Disabled animations on mobile devices */
@media (min-width: 768px) {
  .blob {
    will-change: transform;
    animation: blob-movement 60s infinite alternate;
    transform-origin: center center;
  }

  .blob-1 {
    animation-duration: 30s;
  }
  .blob-2 {
    animation-duration: 40s;
    animation-delay: -10s;
  }
  .blob-3 {
    animation-duration: 50s;
    animation-delay: -20s;
  }
}

@keyframes blob-movement {
  0% {
    transform: rotate(0deg) scale(1) translateZ(0);
  }
  33% {
    transform: rotate(120deg) scale(1.1) translateZ(0);
  }
  66% {
    transform: rotate(240deg) scale(0.9) translateZ(0);
  }
  100% {
    transform: rotate(360deg) scale(1) translateZ(0);
  }
}
</style>
