import { computed } from "vue";
import { token } from "@formkit/utils";
import { useCurrentForm } from "../utils/form";
import type { FormNode } from "../types";

export const usePropModel = (
  props: { input: FormNode },
  prop: string,
  reKey = false,
  afterUpdate = () => {}
) => {
  const form = useCurrentForm();
  const inputType = computed({
    get() {
      return props.input.type;
    },
    set(value) {
      if (form.value) {
        form.value.updateSchemaProperty(props.input.idx, prop, value);
        if (reKey) {
          form.value.updateSchemaProperty(props.input.idx, "key", token());
        }
        afterUpdate();
      }
    },
  });

  return inputType;
};
