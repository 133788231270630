const maxDisplacementScale = 200;

export function dissolveElement(
  element: HTMLElement,
  callback: () => void = () => {}
) {
  // early returns
  if (typeof document === "undefined") return;
  if (element.getAttribute("data-being-destroyed") === "true") return;
  const displacement = document.getElementById("dissolve-filter-displacement");
  const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
  if (!displacement || isFirefox) {
    if (callback) {
      return callback();
    } else {
      element.remove();
      return;
    }
  }

  setRandomSeed();
  element.style.filter = "url(#dissolve-filter)";

  const duration = 200;
  const startTime = performance.now();
  element.setAttribute("data-being-destroyed", "true");

  const animate = (currentTime: number) => {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);
    const displacementScale = progress * maxDisplacementScale;

    displacement.setAttribute("scale", displacementScale.toString());
    element.style.transform = `scale(${1 + 0.2 * progress})`;

    const opacity = 1 - progress * 1.5;
    element.style.opacity = opacity.toString();

    if (progress < 1) {
      requestAnimationFrame(animate);
    } else {
      displacement.setAttribute("scale", "0");
      if (callback) {
        callback();
      } else {
        element.remove();
      }
    }
  };

  requestAnimationFrame(animate);
}

function setRandomSeed() {
  const turbulence = document.getElementById("dissolve-filter-turbulence");
  if (!turbulence) return;
  turbulence.setAttribute("seed", `${Math.random() * 1000}`);
}
